import React, { ChangeEvent, useState, useEffect, ReactElement } from "react";
import { Form, Button, Table } from 'react-bootstrap';
import { Container, Tabelas1, Tabelas } from './styles';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import { ConsultaUsuario } from '../../interfaces/usuarioConsulta';
import { Subordinados } from '../../interfaces/subordinados';
import { ClienteNewconSituacaoCobranca } from "../../interfaces/clienteNewconSituacaoCobranca";
import api from '../../services/api';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useToast } from '../../hooks/toast';
import { BensVendedor } from '../../interfaces/bensVendedor';
import { BensVendaDetalhe } from '../../interfaces/bensVendaDetalhe'
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationConsultaContrato';
import { BensVenda } from '../../interfaces/bensVenda'
import { FiArrowDown, FiDownload, FiArrowUp, FiX, FiEdit2, FiAtSign, FiClock, FiArrowLeft } from 'react-icons/fi';
import { FaMapMarkerAlt, FaHome, FaFileDownload, FaFileSignature } from 'react-icons/fa';
import { MdOutlineAssignmentTurnedIn, MdDelete, MdLocalShipping, MdSend, MdEmail, MdCancel, MdOutlineAttachFile, MdDescription, MdVisibility, MdDateRange, MdInfo, MdSwapHoriz, MdIso, MdDirectionsCar, MdOutlineTimer, MdOutlineShowChart } from 'react-icons/md';
import { FaListOl } from 'react-icons/fa';
import { AiFillDelete, AiOutlineBarcode } from 'react-icons/ai';
import { BsListCheck, BsPrinterFill, BsFillEyeFill, BsFillCloudArrowUpFill } from 'react-icons/bs';
import { VendaBemDetalhe } from "../../interfaces/vendaBemDetalhe";
import { RendaNewcon } from "../../interfaces/rendaNewcon";
import NumberFormat from "react-number-format";
import { Header } from '../../components/Header';
import { DadosPreVendaTodos } from '../../hooks/dadosVenda';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { dadosAssembleia } from '../../mock/calendarioAssembleia'
import { PlanoAgrupamento } from '../../interfaces/planoAgrupamentos'
import { NumeroAssembleia } from "../../interfaces/assembleias";
import jsPDF from "jspdf";
import { logoGroscon } from '../../mock/logo';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { ConsultaContratos } from '../../interfaces/consultaContratos';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { useDropzone } from 'react-dropzone';
import { UploadLista } from '../../interfaces/uploadLista';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { DadosPreVenda } from '../../interfaces/dadosPreVenda';
import moment from 'moment';
import Badge from '@mui/material/Badge';
import { Usuario } from "../../interfaces/usuario";
import { ContatoConsulta } from '../../interfaces/contatoConsulta';
import { Boletoi } from '../../interfaces/boleto';
import { PreVendaSimplificado } from "../../interfaces/preVendaSimplificado";
import { Col, Row } from "../Vendas/styles";
import autoTable from "jspdf-autotable";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ImpressaoConsultaContrato {
  idContrato?: number;
  nomeCliente?: string;
  dataCriacao?: string;
  status?: string;
}

interface Filtro {
  nomeCliente: string;
  numeroContrato: number;
  vendedor: number;
  status: string;
  dataInicio?: string;
  dataFinal?: string;
  age1?: Date;
  age2?: Date;
}

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const styleBox = {
  overflow: 'scroll',
  alignSelf: 'center',
  maxWidth: 800,
  width: '100%',
  maxHeight: 500,
  bgcolor: 'background.paper',
  boxShadow: 2,
  p: 2,

  header: {
    padding: '12px 0',
    borderBottom: '1px solid darkgrey'
  },
  content: {
    padding: 12,
    overflow: 'scroll'
  }
};


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


interface bemSimples {
  codigoGrupo: number,
  codigoGrupoBem: number,
  credito: number,
  dataAssembleia: string,
  descricaoBem: string,
  idBem: number,
  idTabelaVendas: number,
  mediaLance: number,
  numeroMaximoCotas: number,
  parcela: number,
  parcelaSeguro: number,
  planoBasico: number,
  prazo: number,
  taxa: number,
}

const style = {
  position: 'absolute' as 'absolute',
  overflow: 'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxHeight: 500,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  boxShadow: 2,
  p: 4,

  header: {
    padding: '12px 0',
    borderBottom: '1px solid darkgrey'
  },
  content: {
    padding: 12,
    overflow: 'scroll'
  }
};

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}

      getInputRef={inputRef}
      //style={{textAlign: 'center'}}
      style={{ textAlign: 'center', fontSize: '22px', alignItems: 'center', justifyContent: 'center', alignContent: 'center', color: 'rgb(19,52,131)' }}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator

      prefix={"R$ "}
      format={currencyFormatter}
    />
  );
}

function NumberFormatCustom1(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}

      getInputRef={inputRef}
      style={{ textAlign: 'center', fontSize: "22px" }}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalSeparator

      prefix={'% '}
      //suffix={'%'}
      format={"##.####"}
    />
  );
}

function currencyFormatter(value: any) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value / 100);

  return amount
}

function valuetext(value: number) {
  return `${value}asdfasdfsdf`;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 'none',


        }}
      >
        <Typography
          variant="caption"
          component="div"

          color="rgb(19,52,131)"
          fontSize={9}
        >{`${(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function CircularProgressWithLabel1(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', boxShadow: 'inset 0 0 1px 10px #E4F2FE', borderRadius: '50%' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 'none',
          borderRadius: 50

        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="#1976D2"
          fontSize={20}


        >{`${(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const fisica = {
  nomePrincipal: 'Nome',
  nomeSecundario: 'Sobrenome',
  data: 'Data de Nascimento',
  documentoPrincipal: 'CPF',
  mascaraPessoa: '999.999.999-99',
  documentoSecundario: 'RG',
  renda: "Renda Mensal",
  mascaraDinheiro: '#9.999,99'
}

const juridica = {
  nomePrincipal: 'Razão Social',
  nomeSecundario: 'Nome Fantasia',
  data: 'Data Constituição da Empresa',
  documentoPrincipal: 'CNPJ',
  mascaraPessoa: '99.999.999/9999-99',
  documentoSecundario: 'Inscrição Municipal ou Estadual',
  renda: "Faturamento Mensal",
  mascaraDinheiro: '999'
}

const modelFiltros = {
  nomeCliente: "",
  numeroContrato: 0,
  vendedor: 0,
  status: 'TODOS',
  dataInicio: "",
  dataFinal: "",
}


let ass1 = dadosAssembleia.vencimento01


const ConsultaContrato: React.FC = (props: any) => {
  const { addToast } = useToast();
  moment.locale('pt-br');
  const history = useHistory();
  const setDuplicados = new Set();
  const nome = localStorage.getItem('@Gscon:user')
  const { dadosVenda, setDadosVenda, alteraDados } = DadosPreVendaTodos();
  const [valueVendas, setValueVendas] = React.useState(0);
  const [usuarios, setUsuarios] = useState<ConsultaUsuario[]>([]);
  const [usuario, setUsuario] = useState<ConsultaUsuario>();
  const [subordinados, setSubordinados] = useState<Subordinados[]>([]);
  //const { DadosVenda } = props
  const [bensPorVendedor, setBensPorVendedor] = useState<BensVendedor[]>([]);
  const [bensPorVendedor1, setBensPorVendedor1] = useState<BensVenda[]>([]);
  const [grupos, setGrupos] = useState<number[]>([]);
  const [valorBens, setValorBens] = useState<BensVenda[]>([])
  const [maiorValorBem, setMaiorValorBem] = useState<number>(0)
  const [menorValorBem, setMenorValorBem] = useState<number>(0)
  const [maiorPrazoBem, setMaiorPrazoBem] = useState<number>(0)
  const [menorPrazoBem, setMenorPrazoBem] = useState<number>(0)
  const [listaSearchBem, setListaSearchBem] = useState<BensVenda[]>(valorBens)
  const [dadosPreVendaUsuario, setDadosPreVendaUsuario] = useState<PreVendaSimplificado[]>([])
  const [dadosPreVendaUsuarioF, setDadosPreVendaUsuarioF] = useState<PreVendaSimplificado[]>([])
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(dadosPreVendaUsuarioF.length / PER_PAGE);
  const _DATA = usePagination(dadosPreVendaUsuarioF, PER_PAGE);
  const [filtroTipo, setFiltroTipo] = useState<number>(0)
  const [filtroGrupo, setFiltroGrupo] = useState<number>(0)
  const [filtroBem, setFiltroBem] = useState<string>("")
  const [idBemSelecionado, setIdBemSelecionado] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);
  const handleClose = () => (setDadosVenda(
    { ...dadosVenda, reducao: 0 }), setOpen(false));
  const handleClose1 = () => (setDadosVenda(
    { ...dadosVenda, reducao: 0 }), setOpen1(false));
  const handleClose2 = () => (setDadosVenda(
    { ...dadosVenda, reducao: 0 }), setOpen2(false));
  const [reducaoModal, setReducaoModal] = useState<number[]>([])
  const [bemSelecionado, setBemSelecionado] = useState<BensVendaDetalhe[]>([])
  const [show, setShow] = React.useState(false);
  const container = React.useRef(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [valorReducao, setValorReducao] = useState<number>(0);
  const [mostrarModal, setMostrarModal] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false)
  const [rendaTodos, setRendaTodos] = useState<RendaNewcon[]>([])
  const [mensagemRenda, setMensagemRenda] = useState<string>();
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [selectedValue1, setSelectedValue1] = React.useState('a');
  const [valuel, setValuel] = React.useState(0);
  const [assMes, setAssMes] = useState<any>(0);
  const [planosAgrupamento, setPlanosAgrupamento] = useState<PlanoAgrupamento[]>([])
  const [planoAgrupamento, setPlanoAgrupamento] = useState<PlanoAgrupamento[]>([])
  const [coeficiente, setCoeficiente] = useState(0);
  const [visualizar, setVisualizar] = useState<boolean>(false)
  const [excluirLance, setExcluirLance] = useState<boolean>(true)
  const [excluirComparativo, setExcluirComparativo] = useState<boolean>(true)
  const [filtros, setAlteraFiltros] = useState<Filtro>(modelFiltros)
  const [age1, setAge1] = React.useState<Date | null>(null);
  const [age2, setAge2] = React.useState<Date | null>(null);
  const [ordenacaoContrato, setOrdenacaoContrato] = useState(0)
  const [ordenacaoData, setOrdenacaoData] = useState(0)
  const [itemCancelamento, setItemCancelamento] = useState<ConsultaContratos>()
  const [listaArquivos, setListaArquivos] = useState<UploadLista[]>([])
  const [arquivo, setArquivo] = useState<any>()
  const [contatos, setContatos] = useState<ContatoConsulta[]>([])
  const [dadoBoleto, setDadoBoleto] = useState<Boletoi>()

  let idVend: number = 0

  const [value1, setValue1] = React.useState<number[]>([20, 999]);

  const handleChange1 = (event: Event, newValue: number | number[]) => {
    setValue1(newValue as number[]);
  };

  const marks1 = [
    {
      value: { menorPrazoBem },
      label: { menorPrazoBem },
    },
    {
      value: { maiorPrazoBem },
      label: { maiorPrazoBem },
    },
  ];

  useEffect(() => {
    if (arquivo !== undefined) {
      dispararUpload(arquivo)
    }

  }, [setArquivo])

  useEffect(() => {
    // console.log(`bbbbb`)
    if (!open1) {
      // console.log(`ddddd`)
      handleSearchTodos()
    } else {
      // console.log(`eeeeee`)
    }
  }, [setOpen1])

  useEffect(() => {
    buscaUsuarios();
    //carregarPlanos();
    //numeroAssembleia();
    preVenda();

  }, [])

  useEffect(() => {
    handleSearchTodos()
  }, [dadosPreVendaUsuario])


  async function listagemArquivo(vendaID: number) {
    const response = await api.get(`/v1/vendas/${vendaID}/anexos`)
    setListaArquivos(response.data)
  }

  const handleChangel = (event: React.SyntheticEvent, newValue: number) => {
    setValuel(newValue);

  };

  async function listaSubordinados() {
    if (usuario !== null && usuario !== undefined) {
      if (usuario?.dadoUsuario.cargo?.id !== 3) {
        let { data } = await api.get(`/v1/dados-usuario/${usuario?.dadoUsuario.id}/subordinados`)
        setSubordinados(data)
      } else {
        let { data } = await api.get('/v1/dados-usuario/')
        setSubordinados(data)
      }
    }
  }

  const handleChangeDate = (newValue: Date | null) => {
    if (newValue != undefined) {
      const agora = Date.now()
      const hoje = new Date(agora)
      const idade = Math.floor(Math.ceil(Math.abs(newValue.getTime() - hoje.getTime()) / (1000 * 3600 * 24)) / 365.25);
      const idadeF = idade + " ANOS";
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
      let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      setAge1(newValue)
      setAlteraFiltros({ ...filtros, dataInicio: DataFormatada })
      //setAlteraDados({ ...DadosVenda, age1: newValue, dataNascimento: DataFormatada, dataNascimentoF: DataFormatada1, idade: idadeF })
    }

    if (newValue === null) {
      setAlteraFiltros({ ...filtros, dataInicio: '' })
      setAge1(null)
    }
  };

  const handleChangeDate2 = (newValue: Date | null) => {
    if (newValue != undefined) {
      const agora = Date.now()
      const hoje = new Date(agora)
      const idade = Math.floor(Math.ceil(Math.abs(newValue.getTime() - hoje.getTime()) / (1000 * 3600 * 24)) / 365.25);
      const idadeF = idade + " ANOS";
      let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
      let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      setAge2(newValue)
      setAlteraFiltros({ ...filtros, dataFinal: DataFormatada })
      //setAlteraDados({ ...DadosVenda, age1: newValue, dataNascimento: DataFormatada, dataNascimentoF: DataFormatada1, idade: idadeF })
    }

    if (newValue === null) {
      setAlteraFiltros({ ...filtros, dataFinal: '' })
      setAge2(null)
    }
  };

  const handleChangeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDadosVenda({
      ...dadosVenda,
      renda: parseFloat(event.target.value) / 100,
      rendaReal: parseFloat(event.target.value),
      rendaFormatado: (parseFloat(event.target.value) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    })
  };



  const alteraDadosSimulacao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDadosVenda({ ...dadosVenda, [e.target.name]: e.target.value })
  };


  const handleChangeOption1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue1(event.target.value);
    let dadosPlanos = planosAgrupamento.filter(item => item.sequenciaAgrupamento === parseInt(event.target.value))
    setPlanoAgrupamento(dadosPlanos)


  };



  useEffect(() => {
    listaSubordinados();
  }, [usuario])


  function buscaUsuarios() {
    api.get('/v1/usuarios').then((response) => {
      const users: ConsultaUsuario[] = response.data
      const dadosUsuario = users.find(item => item.nome === nome);
      const user: ConsultaUsuario | undefined = dadosUsuario
      setUsuario(dadosUsuario)
      setDadosVenda({ ...dadosVenda, idVendedor: dadosUsuario?.dadoUsuario.id })
      setUsuarios(response.data)
      if (dadosUsuario?.dadoUsuario.listContato !== null && dadosUsuario?.dadoUsuario.listContato !== undefined) {
        setContatos(dadosUsuario.dadoUsuario.listContato)
      }
      api.get(`/v1/tabelas-venda-usuario/dado-usuario/${dadosUsuario?.dadoUsuario.id}/calculado`).then((response1) => {
        setBensPorVendedor(response1.data)
        const teste: BensVendedor[] = response1.data
        const unico = teste[0].bens.filter((item) => {
          const duplicados = setDuplicados.has(item.idBem)
          setDuplicados.add(item.idBem)
          return !duplicados
        })
        const unicoOrdem = unico.sort(function (a, b) {
          if (a.idBem > b.idBem) {
            return 1
          }
          if (a.idBem < b.idBem) {
            return -1
          }
          return 0;
        })
        //setBensPorVendedor(response1.data)
        setBensPorVendedor1(unicoOrdem)
        setValorBens(response1.data[0].bens)
        setListaSearchBem(response1.data[0].bens)
        const dadosBens: BensVenda[] = response1.data[0].bens
        let grupos = dadosBens?.map(item => item.codigoGrupo)
        let gruposFiltro = grupos.filter(function (item, i) {
          return grupos.indexOf(item) === i;
        })
        let gruposOrdem = gruposFiltro.sort()
        setGrupos(gruposOrdem)

        let valoresBens = dadosBens?.map(item => item.credito)
        let maiorValorBem = Math.max(...valoresBens)
        let menorValorBem = Math.min(...valoresBens)
        setMaiorValorBem(maiorValorBem)
        setMenorValorBem(menorValorBem)

        let mesesBens = dadosBens?.map(item => item.prazo)
        let maiorPrazoBem = Math.max(...mesesBens)
        let menorPrazoBem = Math.min(...mesesBens)
        setMaiorPrazoBem(maiorPrazoBem)
        setMenorPrazoBem(menorPrazoBem)
      }).catch((error) => {
        console.log('Ocorreu erro consulta dado-usuario')
      })
    }).catch((error) => {
      console.log("ocorreu um erro na consulta Usuarios")
    })


  }




  function buscaDadosUsuario() {
    if (usuarios) {
      const dadosUsuario = Array.isArray(usuarios) ? usuarios.find(item => item.nome === nome) : undefined;
      setUsuario(dadosUsuario)
      setDadosVenda({ ...dadosVenda, idVendedor: dadosUsuario?.dadoUsuario.id })
    }
  }


  async function DadosFiltros() {
    let grupos = valorBens?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    setGrupos(gruposFiltro)

    let valoresBens = valorBens?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = valorBens?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)

  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault()
  }

  function handleSearchTodos() {

    console.log(dadosPreVendaUsuario)
    setDadosVenda({ ...dadosVenda, visualizaBens: true })
    let listaGeral: PreVendaSimplificado[];

    if (usuario?.dadoUsuario.cargo?.id === 3) {
      listaGeral = dadosPreVendaUsuario;
      setDadosPreVendaUsuarioF(listaGeral)
    } else {
      const lista: PreVendaSimplificado[] = dadosPreVendaUsuario.filter(item => subordinados.some((item2) => (item.idVendedor === item2.dadoUsuarioId)) || item.idVendedor === usuario?.dadoUsuario.id)
      listaGeral = lista
      setDadosPreVendaUsuarioF(lista)
      console.log(listaGeral)
      console.log(usuario?.dadoUsuario.id)
    }




    if (filtros.nomeCliente === "") {
      setDadosPreVendaUsuarioF(listaGeral)
    } else {
      const listaTirandoNomesBranco: PreVendaSimplificado[] = listaGeral.filter(item => item.nomeCliente !== undefined);
      const lista: PreVendaSimplificado[] = listaTirandoNomesBranco.filter(item => filtros.nomeCliente ? item.nomeCliente.toLowerCase().includes(filtros.nomeCliente.toLowerCase()) : item);
      listaGeral = lista
      setDadosPreVendaUsuarioF(lista)
    };

    if (filtros.vendedor !== 0) {
      const lista: PreVendaSimplificado[] = listaGeral.filter(item => item.idVendedor === filtros.vendedor)
      listaGeral = lista
      setDadosPreVendaUsuarioF(lista)
    }

    //if(item.idVendedor === filtros.vendedor)
    //console.log(listaGeral)

    if (filtros.numeroContrato > 0 && filtros.numeroContrato !== undefined) {
      const listaTirandoContratosBranco: PreVendaSimplificado[] = listaGeral.filter(item => item.idContrato !== undefined);
      const lista: PreVendaSimplificado[] = listaTirandoContratosBranco.filter(item => filtros.numeroContrato ? item.idContrato.toString().includes(filtros.numeroContrato.toString()) : item);
      listaGeral = lista
      setDadosPreVendaUsuarioF(lista)
    };


    if (filtros.status !== "TODOS") {
      const listaTirandoStatusBranco: PreVendaSimplificado[] = listaGeral.filter(item => item.status !== undefined);
      const lista: PreVendaSimplificado[] = listaTirandoStatusBranco.filter(item => filtros.status ? item.status.toLowerCase().includes(filtros.status.toLowerCase()) : item);
      listaGeral = lista
      setDadosPreVendaUsuarioF(lista)
    }


   // console.log()
    if (age1 !== null) {
      const lista: PreVendaSimplificado[] = listaGeral.filter(function (item) {
        let dataInicio = new Date(item.dataCriacao)
        if (moment(dataInicio) >= moment(age1).subtract(1, 'd')){
          console.log('data Inicio')
        console.log(item.dataCriacao , age1)
          return item
        }
      });
      listaGeral = lista
      setDadosPreVendaUsuarioF(lista)
    }


    if (age2 !== null) {
      const lista: PreVendaSimplificado[] = listaGeral.filter(function (item) {
        let dataFinal = new Date(item.dataCriacao)
        if (dataFinal <= age2){
          console.log('data Final')
          console.log(item.dataCriacao , age2)
          return item
        }
      });
      listaGeral = lista
      setDadosPreVendaUsuarioF(lista)
    }

    setPage(1)
    _DATA.jump(1);



  }

  function insereGrupo(grupo: number) {
    const grupo1 = grupos.find(item => (
      item === grupo
    ))
    if (grupo1 === undefined)
      setGrupos([...grupos, grupo])

  }

  const [value, setValue] = React.useState<number[]>([20000, 1800000]);
  const [camposPessoa, setCamposPessoa] = React.useState(dadosVenda?.pessoa === "fisica" ? fisica : juridica);


  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const marks = [
    {
      value: { menorValorBem },
      label: { menorValorBem },
    },
    {
      value: { maiorValorBem },
      label: { maiorValorBem },
    },
  ];



  const [model, setModel] = useState({
    tipoPessoa: 'PESSOA_FISICA',
    vendedor: { id: 0 },
    documentoCliente: '',
    renda: 'Renda Mensal',
    valorRenda: 0
  })




  function handleChangeBem(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroBem(e.target.value)
  }

  function handleChangeGrupo(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroGrupo(parseInt(e.target.value))

    let listaGeral: BensVenda[];
    if (parseInt(e.target.value) === 0) {
      listaGeral = valorBens;
    } else {
      const lista: BensVenda[] = valorBens.filter(item => parseInt(e.target.value) ? item.codigoGrupo === parseInt(e.target.value) : item)
      listaGeral = lista

    };

    let grupos = listaGeral?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    setDadosVenda({ ...dadosVenda, grupo: e.target.value })

    let valoresBens = listaGeral?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = listaGeral?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)

  }

  function handleChangeVendedor(e: ChangeEvent<HTMLSelectElement>) {

    if (e.target.name === 'vendedor') {
      let valor = parseInt(e.target.value)
      setAlteraFiltros({
        ...filtros,
        [e.target.name]: valor
      })
    } else {
      setAlteraFiltros({
        ...filtros,
        [e.target.name]: e.target.value
      })
    }





  }

  function handleChangeTipo(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroTipo(parseInt(e.target.value))

    let listaGeral: BensVenda[];
    if (parseInt(e.target.value) === 0) {
      listaGeral = valorBens;
    } else {
      const lista: BensVenda[] = valorBens.filter(item => parseInt(e.target.value) ? item.codigoGrupoBem === parseInt(e.target.value) : item)
      listaGeral = lista

    };

    let grupos = listaGeral?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    setGrupos(gruposFiltro)

    let valoresBens = listaGeral?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = listaGeral?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)
  }

  function handleChangeBemInput(e: ChangeEvent<HTMLInputElement>) {
    if (itemCancelamento !== undefined) {
      setItemCancelamento({ ...itemCancelamento, motivoCancelamento: e.target.value })
    }
  }

  function handleChangeBemInput1(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "nomeCliente") {
      setAlteraFiltros({ ...filtros, nomeCliente: e.target.value })
    } else {
      setAlteraFiltros({ ...filtros, numeroContrato: parseInt(e.target.value) })
    }
  }


  async function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;
    resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");

    if (e.target.name === "documentoCliente" && resultado.length === 11 && e.target.id === "CPF") {
      const result = TestaCPF(resultado)
      if (!result) {
        addToast({
          type: 'error',
          title: 'CPF Inválido',
          description: 'Por favor insira um CPF Válido!'
        });
        return;
      }
    }

    setModel({
      ...model,
      [e.target.name]: resultado
    });

    setDadosVenda({ ...dadosVenda, documentoFormatado: e.target.value, documento: resultado })

    //buscaRendaCPF
    let response = await api.get(`/v1/parcelas-calculo-renda-newcon`)
    setRendaTodos(response.data)

  }

  async function handleChangeValor(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;
    resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");


  }

  function TestaCPF(strCPF: string) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  function filtroPorSituacao(x: ClienteNewconSituacaoCobranca) {
    if (x.codigoSituacao === 'J00' || x.codigoSituacao === 'J01' || x.codigoSituacao === 'JCO')
      return x
  }

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const handleChangePagination = (event: object, e: number) => {
    setPage(e);
    _DATA.jump(e);
  };


  function mostrar(seq: number) {
    if (mostrarModal === seq) {
      setVisible(visible === true ? false : true)
    } else {
      setVisible(true)
      setMostrarModal(seq)
    }
  }

  async function handleChangeCheckReducao(e: ChangeEvent<HTMLInputElement>) {
    setValorReducao(parseInt(e.target.value))
    setDadosVenda({ ...dadosVenda, reducao: parseInt(e.target.value) })
    setBemSelecionado(bemSelecionado)

    bemSelecionado.map(item => {
      if (item.reducao === parseInt(e.target.value) && item.parcelaInicial === 1 && dadosVenda.valorCredito !== undefined && item.adesao === 0) {
        selecionaBemFinal(item)
        setSelectedValue1(item.sequenciaAgrupamento.toString())
        let dadosPlanos = planosAgrupamento.filter(item2 => item2.sequenciaAgrupamento === item.sequenciaAgrupamento)
        setPlanoAgrupamento(dadosPlanos)
      }
    })
  }

  function dataDia() {
    let dataDia = Date.now()
    let dataAss = new Date(dataDia)
    let DataFormatada = ((adicionaZero(dataAss.getDate())) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }

  function formatData(x: string) {
    let dataAss = new Date(x)
    let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }

  function limparTodos() {
    setAlteraFiltros({ ...modelFiltros, vendedor: 0 })
    setAge1(null)
    setAge2(null)
    preVenda()

  }

  async function selecionaBemFinal(bem: VendaBemDetalhe) {
    if (dadosVenda.valorCredito !== null) {
      let valorFinanciamento = ((dadosVenda.valorCredito || 1) * coeficiente)
      let valorFinanciamentoF = valorFinanciamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let diferencaParcelas = valorFinanciamento - (dadosVenda.valorParcela || 1)
      let diferencaParcelasF = diferencaParcelas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let diferencaTotal = diferencaParcelas * (dadosVenda.prazo || 1)
      let diferencaTotalF = diferencaTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      let percentualDif = ((((dadosVenda.valorParcela || 1) * (dadosVenda.prazo || 1)) - (valorFinanciamento * (dadosVenda.prazo || 1))) / ((dadosVenda.valorParcela || 1) * (dadosVenda.prazo || 1)) * 100) * -1
      let percentualDifF = Math.round(percentualDif * 100) / 100
      let percentualIcone = (20 / 100 * percentualDif) + 'px'

      setDadosVenda({
        ...dadosVenda,
        sequenciaAgrupamento: bem.sequenciaAgrupamento,
        valorParcelaFormatado: bem.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorParcela: bem.valorParcela,
        reciboPrimeiraParcela: bem.valorParcela * 100,
        reciboPrimeiraParcelaFormatado: bem.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorParcelaComSeguroFormatado: bem.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorParcelaComSeguro: bem.valorParcelaComSeguro * 100,
        adesao: bem.adesao,
        reducao: bem.reducao,

      })
    }
  }

  function teste() {
    addToast({
      type: 'success',
      title: 'Re-envio do Email',
      description: 'Verifiquei caixa de entrada e Spam!'
    });
  }

  function teste1() {
    console.log(filtros)

  }

  async function salvarCancelamento() {
    const item = itemCancelamento
    if (item?.anexos)
      delete item.anexos
    const response = await api.put(`v1/pre-vendas/${itemCancelamento?.id}`, {
      ...item,
      status: "CANCELADO",
      posVenda: "CANCELADO"
    })
    handleClose()
    preVenda();



  }

  function preVenda() {

    api.get('/v1/usuarios').then((response) => {
      const users: ConsultaUsuario[] = response.data
      const dadosUsuario = users.find(item => item.nome === nome)



      api.get(`/v1/pre-vendas/simples`).then((response) => {
        const dados: PreVendaSimplificado[] = response.data
        console.log(`chegou na parte que tinha que ter os dados`)
        console.log(dados)
        //setAlteraFiltros({...filtros, vendedor: dadosUsuario?.dadoUsuario.id||0 })
        const dadosFData = dados.sort(function (a, b) {
          let dataA = new Date(a.dataCriacao)
          let dataB = new Date(b.dataCriacao)
          if (dataA.getTime() > dataB.getTime()) {
            return -1
          }
          if (dataB.getTime() < dataB.getTime()) {
            return 1
          }

          return 0
        })
        const dadosFContrato = dados.sort(function (a, b) {
          let valorA = a.idContrato
          let valorB = b.idContrato
          if (a.idContrato === undefined)
            valorA = 0
          if (b.idContrato === undefined)
            valorB = 0
          if (valorA > valorB) {
            return -1
          }
          if (valorA < valorB) {
            return 1
          }

          return 0
        })

        setDadosPreVendaUsuario(dadosFContrato)


      })


    })
  }

  function renderCaracteristica(title: string, text: string, icon: ReactElement) {
    return <Col>
      <p className="title-carac">{title}</p>
      <div style={{ display: 'flex', flexDirection: "row" }}>
        {icon}
        <p className="text-carac">{text}</p>
      </div>
    </Col>
  }

  function retornoFinanciamento(vbem: number, vprazo: number) {
    let juros = 1.5 / 100
    const bem = vbem || 0
    if (dadosVenda.descTipo === 'IMÓVEL') {
      juros = 0.8 / 100
    } else {
      juros = 1.5 / 100
    }

    var parcelas = vprazo || 0


    var coeficienteFinanciamento = (juros / (1 - (1 / ((1 + juros) ** parcelas))))
    var valorParcela = (bem * coeficienteFinanciamento)

    return valorParcela
  }

  function voltar() {
    setDadosVenda({
      ...dadosVenda, codB: 0, sequenciaAgrupamento: 0,
      reducao: 0,
    })
    setValuel(0)
    setValorReducao(0)
    setOpen(false)
    setMensagemRenda('')
    setSelectedValue('a')
    setSelectedValue1('a')
  }

  function alteraTipoLance(tipo: string) {

    let lanceFixo = parseFloat(dadosVenda.numero || '31')
    if (lanceFixo < 30) {
      addToast({
        type: 'error',
        title: 'Percentual Máximo do Grupo é menor que 30%',
        description: `Valor máximo é 100%`
      });
    }
    let valorMaximo = parseInt(((dadosVenda.valorCredito || 1) / 100 * 30 * 100).toString())
    if (tipo === "fixo") {
      setDadosVenda({
        ...dadosVenda,
        b2: '30',
        t_p2: valorMaximo,
        t_p3: 0,
        b3: '0',
        b4: (valorMaximo / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        b5: (dadosVenda.valorCredito || 1).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        b1: tipo
      })
    } else {
      setDadosVenda({
        ...dadosVenda,
        b2: '0',
        t_p2: 0,
        t_p3: 0,
        b3: '0',
        b4: (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        b5: (dadosVenda.valorCredito || 1).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        b1: tipo

      })
    }

  }

  function limparCalculadora() {
    setDadosVenda({
      ...dadosVenda,

      b3: '0',
      t_p3: 0,
      b2: '0',
      t_p2: 0,
      b4: (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      b5: ((dadosVenda.valorCredito || 1)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    })
  }

  function excluirLance1() {
    excluirLance ? setExcluirLance(false) : setExcluirLance(true)
  }

  function excluirComparativo1() {
    excluirComparativo ? setExcluirComparativo(false) : setExcluirComparativo(true)
  }

  function visualizarSimulacao() {
    if (!visualizar) {
      setVisualizar(true)
    } else (
      setVisualizar(false)
    )

    setExcluirComparativo(true)
    setExcluirLance(true)
  }

  function testeEnvio(item: PreVendaSimplificado) {

    if (item.idContrato) {
      addToast({
        type: 'info',
        title: 'Atenção!',
        description: 'Não pode editar contratos que foram finalizados!'
      });
      return
    }

    history.push(`/Vendas/${item.id}`)
  }

  function ordenarContrato() {
    if (ordenacaoContrato === 0) {
      setOrdenacaoContrato(1)

      const dadosFContrato = dadosPreVendaUsuarioF.sort(function (a, b) {
        let valorA = a.idContrato
        let valorB = b.idContrato
        if (a.idContrato === undefined)
          valorA = 0
        if (b.idContrato === undefined)
          valorB = 0
        if (valorA > valorB) {
          return -1
        }
        if (valorA < valorB) {
          return 1
        }

        return 0
      })
      setDadosPreVendaUsuarioF(dadosFContrato)
    } else {
      setOrdenacaoContrato(0)
      const dadosFData = dadosPreVendaUsuarioF.sort(function (a, b) {
        let dataA = new Date(a.dataCriacao)
        let dataB = new Date(b.dataCriacao)
        if (dataA.getTime() > dataB.getTime()) {
          return -1
        }
        if (dataB.getTime() < dataB.getTime()) {
          return 1
        }

        return 0
      })
      const dadosFContrato = dadosFData.sort(function (a, b) {
        if (a.idContrato || 0 > b.idContrato || 0) {
          return -1
        }
        if (a.idContrato || 0 < b.idContrato || 0) {
          return 1
        }

        return 0
      })
      setDadosPreVendaUsuarioF(dadosFContrato)
    }


  }

  function ordenarData() {
    if (ordenacaoData === 0) {
      setOrdenacaoData(1)
      const dadosFData = dadosPreVendaUsuarioF.sort(function (a, b) {
        let dataA = new Date(a.dataCriacao)
        let dataB = new Date(b.dataCriacao)
        if (dataA.getTime() > dataB.getTime()) {
          return -1
        }
        if (dataB.getTime() < dataB.getTime()) {
          return 1
        }

        return 0
      })
      setDadosPreVendaUsuarioF(dadosFData)
    } else {
      setOrdenacaoData(0)
      const dadosFData = dadosPreVendaUsuarioF.sort(function (a, b) {
        let dataA = new Date(a.dataCriacao)
        let dataB = new Date(b.dataCriacao)
        if (dataA.getTime() < dataB.getTime()) {
          return -1
        }
        if (dataB.getTime() > dataB.getTime()) {
          return 1
        }

        return 0
      })
      setDadosPreVendaUsuarioF(dadosFData)
    }
  }

  async function buscaBoleto(id: number) {
    const response = await api.get(`/v1/boletos`)
    const boletos: Boletoi[] = response.data

    const boleto: Boletoi[] = boletos.filter(item => item.numeroDocumento === id)

    console.log('boleto ', boleto)

    setDadoBoleto(boleto[0])
    console.log(response.data)
  }

  async function dadosPreCancelamento(item: PreVendaSimplificado) {
    const response = await api.get(`/v1/pre-vendas/${item.id}`)
    const itemx: ConsultaContratos = response.data[0];

    dadosCancelamento(itemx)

  }

  function dadosCancelamento(item: ConsultaContratos) {
    setItemCancelamento(item)
    setOpen(true)
  }

  function dadosBoleto(item: PreVendaSimplificado) {
    buscaBoleto(item.idContrato)
    setOpen2(true)
  }

  async function dadosPreUpload(item: PreVendaSimplificado) {
    const response = await api.get(`/v1/pre-vendas/${item.id}`)
    const itemx: ConsultaContratos = response.data[0];

    dadosUpload(itemx)
  }

  async function reenviarD4Sign(item: PreVendaSimplificado) {
    addToast({
      type: 'info',
      title: 'Atenção!',
      description: 'Aguarde o fim do processamento!'
    });
    let dadosPre = await api.get(`/v1/pre-vendas/${item.id}`)
    const itemx: ConsultaContratos = dadosPre.data[0];

    let enviaD4Sign = await api.post(`/v1/vendas/${itemx.idVenda}/reprocessar`)

    console.log(enviaD4Sign)

    if(enviaD4Sign.status === 201){
      addToast({
        type: 'success',
        title: 'Atenção!',
        description: 'Processado com sucesso!'
      });
    }else{
      addToast({
        type: 'error',
        title: 'Atenção!',
        description: 'Faça novo contrato não foi possivel reprocessar!'
      });
    }
  }

  function dadosUpload(item: ConsultaContratos) {
    console.log(item)
    setItemCancelamento(item)
    idVend = item.idVenda || 0
    listagemArquivo(item.idVenda)

    setOpen1(true)
  }

  function mostrarItemX() {
  }

  const print = (tipo: string) => {
    const dataAtual = moment().format('DD/MM/YYYY HH:mm:ss')
    let usuario = 'ANDRÉ FIRMINO'
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.setFontSize(9);
    pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
    pdf.text(`USUÁRIO: ${usuario}`, 200, 12, { align: 'right' });
    pdf.text("TELEFONE (16)3701-7725", 200, 17, { align: 'right' });
    pdf.text(`DATA : ${dataAtual}`, 200, 22, { align: 'right' });
    pdf.line(10, 25, 200, 25); // horizontal line
    pdf.setFontSize(15);
    pdf.setFont("arial", "bold")
    pdf.text("DADOS DO CONTRATO", 105, 33, { align: 'center' });
    pdf.line(10, 37, 200, 37);
    pdf.setFontSize(10);
    pdf.setFont("times", "normal");
    pdf.setDrawColor(0);
    pdf.setFillColor(255, 255, 255);
    pdf.roundedRect(10, 40, 190, 32, 1, 1, "FD");
    pdf.setDrawColor(0);
    pdf.setFillColor(190, 190, 190)
    pdf.setDrawColor(190, 190, 190)
    pdf.roundedRect(15, 44, 120, 10, 2, 2, "FD");
    pdf.roundedRect(138, 44, 58, 10, 2, 2, "FD");
    pdf.roundedRect(15, 58, 181, 10, 2, 2, "FD");
    pdf.text(`Nome:${dadosVenda.emailCom1?.toUpperCase()}`, 20, 50, { align: 'left' });
    pdf.text(`Telefone ${dadosVenda.emailCom2.toUpperCase()} ${dadosVenda.emailCom3.toUpperCase()} `, 180, 50, { align: 'right' });
    pdf.text(`E-mail: ${dadosVenda.emailCom4.toUpperCase()}`, 20, 64, { align: 'left' });

    pdf.setDrawColor(0);
    pdf.setFillColor(255, 255, 255);
    pdf.roundedRect(10, 74, 190, 84, 1, 1, "FD");
    pdf.setDrawColor(0);
    pdf.setFillColor(190, 190, 190)
    pdf.setDrawColor(190, 190, 190)
    pdf.roundedRect(15, 88, 44, 20, 2, 2, "FD");
    pdf.roundedRect(61, 88, 44, 20, 2, 2, "FD");
    pdf.roundedRect(107, 88, 44, 20, 2, 2, "FD");
    pdf.roundedRect(153, 88, 44, 20, 2, 2, "FD");
    pdf.roundedRect(15, 110, 44, 20, 2, 2, "FD");
    pdf.roundedRect(61, 110, 44, 20, 2, 2, "FD");
    pdf.roundedRect(107, 110, 44, 20, 2, 2, "FD");
    pdf.roundedRect(153, 110, 44, 20, 2, 2, "FD");
    pdf.roundedRect(15, 132, 90, 20, 2, 2, "FD");
    pdf.roundedRect(107, 132, 90, 20, 2, 2, "FD");
    //Titulos
    pdf.setFontSize(11);
    pdf.setFont("times", "bold")
    pdf.text("SIMULAÇÃO DE CONSÓRCIO", 105, 83, { align: 'center' });
    pdf.setFontSize(10);
    pdf.setFont("times", "normal");
    pdf.text("Grupo", 37, 95, { align: 'center' });
    pdf.text("Taxa administração", 83, 95, { align: 'center' });
    pdf.text("Adesão", 128, 95, { align: 'center' });
    pdf.text("Valor Crédito", 175, 95, { align: 'center' });

    pdf.text("Redução", 37, 117, { align: 'center' });
    pdf.text("Média Lance", 83, 117, { align: 'center' });
    pdf.text("Prazo", 128, 117, { align: 'center' });
    pdf.text("Próxima Assembleia", 175, 117, { align: 'center' });

    pdf.text("Parcela", 60, 139, { align: 'center' });
    pdf.text("Parcela com seguro", 152, 139, { align: 'center' });

    pdf.setFontSize(14);
    pdf.setFont("times", "normal")
    pdf.text((dadosVenda.grupo || 0).toString(), 37, 103, { align: 'center' });
    pdf.text(`${dadosVenda.taxaAdministracao}%`, 83, 103, { align: 'center' });
    pdf.text(`${dadosVenda.adesao}%`, 128, 103, { align: 'center' });
    pdf.text((dadosVenda.valorCreditoF || ''), 175, 103, { align: 'center' });

    pdf.text((dadosVenda.reducao || 0).toString(), 37, 125, { align: 'center' });
    pdf.text(`${dadosVenda.mediaLance}%`, 83, 125, { align: 'center' });
    pdf.text((dadosVenda.prazo || 0).toString(), 128, 125, { align: 'center' });
    pdf.text(dadosVenda.primeiraAssF, 175, 125, { align: 'center' });

    pdf.text((dadosVenda.valorParcelaFormatado || ""), 60, 147, { align: 'center' });
    pdf.text((dadosVenda.valorParcelaComSeguroFormatado || ""), 152, 147, { align: 'center' });

    //simulacao lance
    if (excluirLance) {
      pdf.setDrawColor(0);
      pdf.setFillColor(255, 255, 255);
      pdf.roundedRect(10, 160, 190, 84, 1, 1, "FD");
      pdf.setFontSize(11);
      pdf.setFont("times", "bold")
      pdf.text("SIMULAÇÃO DO LANCE", 105, 169, { align: 'center' });
      pdf.setDrawColor(0);
      pdf.setFillColor(190, 190, 190)
      pdf.setDrawColor(190, 190, 190)
      pdf.roundedRect(15, 172, 90, 20, 2, 2, "FD");
      pdf.roundedRect(107, 172, 90, 20, 2, 2, "FD");
      pdf.roundedRect(15, 194, 90, 20, 2, 2, "FD");
      pdf.roundedRect(107, 194, 90, 20, 2, 2, "FD");
      pdf.roundedRect(15, 216, 182, 20, 2, 2, "FD");

      //Titulos
      pdf.setFontSize(10);
      pdf.setFont("times", "normal");
      pdf.text("Percentual de lance", 60, 179, { align: 'center' });
      pdf.text("Valor total do lance", 152, 179, { align: 'center' });
      pdf.text("Valor do lance embutido", 60, 201, { align: 'center' });
      pdf.text("Valor do lance com recursos próprios", 152, 201, { align: 'center' });
      pdf.text("Crédito liquido com lance embutido", 106, 223, { align: 'center' });

      pdf.setFontSize(14);
      pdf.setFont("times", "normal")
      pdf.text(`${dadosVenda.b2}%`, 60, 187, { align: 'center' });
      pdf.text(`${((dadosVenda.t_p2 || 1) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, 152, 187, { align: 'center' });
      pdf.text(`${((dadosVenda.t_p3 || 1) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, 60, 208, { align: 'center' });
      pdf.text((dadosVenda.b4 || ""), 152, 208, { align: 'center' });
      pdf.text((dadosVenda.b5 || ""), 106, 231, { align: 'center' });
    }

    //rodapé
    pdf.setFontSize(10);
    pdf.setFont("times", "normal");
    pdf.text("Os valores expressos na simulação são aproximados e poderão ser reajustados sem aviso prévio", 10, 277, { align: 'left' });
    pdf.text("Groscon Administradora de Consórcios LTDA", 200, 285, { align: 'right' });
    pdf.setDrawColor(0, 0, 0);
    pdf.line(10, 280, 200, 280);

    if (excluirComparativo) {
      //Pagina 2***********************
      pdf.addPage("a4");
      pdf.setFontSize(9);
      pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
      pdf.text(`USUÁRIO: ${usuario}`, 200, 12, { align: 'right' });
      pdf.text("TELEFONE (16) 3701-7725", 200, 17, { align: 'right' });
      pdf.text("DATA/HORA EMISSÃO: 25/10/2022 00H30", 200, 22, { align: 'right' });
      //pdf.table(23, 58, generateData(100), headers ,{ autoSize: true });
      pdf.line(10, 25, 200, 25); // horizontal line
      pdf.setFontSize(15);
      pdf.setFont("arial", "bold")
      pdf.text("Comparativo financiamento X Consórcio Groscon", 105, 33, { align: 'center' });
      pdf.line(10, 37, 200, 37);

      pdf.setDrawColor(0);
      pdf.setFillColor(255, 255, 255);
      pdf.roundedRect(10, 43, 190, 150, 1, 1, "FD");//14
      pdf.setDrawColor(0);
      pdf.setFillColor(190, 190, 190)
      pdf.setDrawColor(190, 190, 190)
      pdf.roundedRect(15, 54, 44, 20, 2, 2, "FD");
      pdf.roundedRect(61, 54, 44, 20, 2, 2, "FD");
      pdf.roundedRect(107, 54, 44, 20, 2, 2, "FD");
      pdf.roundedRect(153, 54, 44, 20, 2, 2, "FD");
      pdf.roundedRect(15, 89, 44, 20, 2, 2, "FD");
      pdf.roundedRect(61, 89, 44, 20, 2, 2, "FD");
      pdf.roundedRect(107, 89, 44, 20, 2, 2, "FD");
      pdf.roundedRect(153, 89, 44, 20, 2, 2, "FD");
      pdf.roundedRect(15, 124, 90, 20, 2, 2, "FD");
      pdf.roundedRect(107, 124, 90, 20, 2, 2, "FD");

      pdf.setFontSize(11);
      pdf.setFont("times", "bold")
      pdf.text("CONSÓRCIO GROSCON", 105, 50, { align: 'center' });
      pdf.text("FINANCIAMENTO", 105, 85, { align: 'center' });
      pdf.text("ECONOMIA COM O CONSÓRCIO GROSCON", 105, 120, { align: 'center' });
      pdf.setFontSize(10);
      pdf.setFont("times", "normal");
      pdf.text("Valor do bem", 37, 61, { align: 'center' });
      pdf.text("Prazo", 83, 61, { align: 'center' });
      pdf.text("Taxa de administração mensal", 129, 61, { align: 'center' });
      pdf.text("Valor da parcela mensal", 175, 61, { align: 'center' });

      pdf.text("Valor do bem", 37, 96, { align: 'center' });
      pdf.text("Prazo", 83, 96, { align: 'center' });
      pdf.text("Taxa Mensal", 128, 96, { align: 'center' });
      pdf.text("Valor da Parcela Mensal", 175, 96, { align: 'center' });

      pdf.text("Mensal", 60, 131, { align: 'center' });
      pdf.text("Total", 152, 131, { align: 'center' });

      pdf.setFontSize(14);
      pdf.setFont("times", "normal")
      pdf.text((dadosVenda.valorCreditoF || ''), 37, 68, { align: 'center' });
      pdf.text((dadosVenda.prazo || 0).toString(), 83, 68, { align: 'center' });
      pdf.text(`${dadosVenda.nomePai?.replace('.', ',')}%`, 128, 68, { align: 'center' });
      pdf.text((dadosVenda.valorParcelaFormatado || ''), 175, 68, { align: 'center' });

      pdf.text((dadosVenda.valorCreditoF || ''), 37, 103, { align: 'center' });
      pdf.text((dadosVenda.prazo || 0).toString(), 83, 103, { align: 'center' });
      pdf.text((dadosVenda.descTipo === 'IMÓVEL' ? '0,80%' : '1,50%'), 128, 103, { align: 'center' });
      pdf.text((dadosVenda.cidade || ""), 175, 103, { align: 'center' });

      pdf.text((dadosVenda.uf || ""), 60, 138, { align: 'center' });
      pdf.text((dadosVenda.apto || ""), 152, 138, { align: 'center' });


      pdf.setLineWidth(2);
      pdf.setDrawColor(180, 180, 180);
      pdf.setFillColor(255, 255, 255);
      pdf.circle(106, 170, 15, "FD");

      pdf.setFontSize(20);
      pdf.setFont("times", "normal")
      pdf.text(`${dadosVenda.codBanco}%`, 106, 173, { align: 'center' });

      pdf.setFontSize(10);
      pdf.setFont("times", "normal")
      pdf.text("Economia(%)", 106, 190, { align: 'center' });
      pdf.setLineWidth(0);
      //pdf.table(10, 180, generateData(10), headers ,{ autoSize: false });

      //rodapé
      pdf.setFontSize(10);
      pdf.setFont("times", "normal");
      pdf.text("Os valores expressos na simulação são aproximados e poderão ser reajustados sem aviso prévio", 10, 277, { align: 'left' });
      pdf.text("Groscon Administradora de Consórcios LTDA", 200, 285, { align: 'right' });
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 280, 200, 280);

    }
    if (tipo === "imprimir") {
      window.open(pdf.output('bloburl'));
    } else {
      pdf.save('Simulacao')
    }

  };

  function consultaContratoImpressao() {
    const dataAtual = moment().format('DD/MM/YYYY HH:mm:ss')
    const pdf = new jsPDF("p", "mm", "a4");
    const nome = localStorage.getItem('@Gscon:user')?.toUpperCase()

    let body: any = []
    dadosPreVendaUsuarioF.map(item => item.etapa > 2 ? body.push([moment(item.dataCriacao).format('DD/MM/YYYY'),
    item.idContrato, item.nomeCliente, item.valorCredito, item.status, item.tipoAssinatura==='DIGITAL'?'DIGITAL':'MANUAL', item.tipoPagamento==='BOLETO'?'BOLETO':'MANUAL']) : "")

    autoTable(pdf, {
      head: [['Data Cadastro', 'Contrato', 'Nome Cliente', 'Valor Crédito', 'Status', 'Assinatura', 'Pagamento']],
      body: body,
      margin: { top: 27, bottom: 30, left: 10, right: 10 },
      willDrawPage: function (data) {
        pdf.setFontSize(8);
        pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
        pdf.text(`FILTROS : VENDEDOR: ${filtros.vendedor === 0 ? "TODOS" : filtros.vendedor} | STATUS: ${filtros.status} | DATA: ${(age1 || age2) === null ? " " : moment(age1).format('DD/MM/YYYY') + ' até ' + moment(age2).format('DD/MM/YYYY')}`, 10, 25, { align: 'left' });
        pdf.setFontSize(7);
        pdf.text(`USUÁRIO: ${nome}`, 200, 17, { align: 'right' });
        pdf.text(`DATA : ${dataAtual}`, 200, 12, { align: 'right' });
        pdf.setFontSize(13);
        pdf.setFont("arial", "bold")
        pdf.text("CONTRATOS", 105, 15, { align: 'center' });


      },
      didDrawPage: function (data) {
        pdf.setFontSize(10);
        pdf.setFont("times", "normal");
        pdf.text(`Página ${pdf.getNumberOfPages()}`, 10, 285, { align: 'left' });
        pdf.text("Groscon Administradora de Consórcios LTDA", 200, 285, { align: 'right' });
        pdf.setDrawColor(0, 0, 0);
        pdf.line(10, 280, 200, 280);
      },
      headStyles: { halign: 'center' },
      columnStyles: { 0: { halign: 'center', fontSize: 8 }, 1: { halign: 'center', fontSize: 8 }, 2: { fontSize: 8 }, 3: { halign: 'center', fontSize: 8, cellWidth: 25 }, 4: { halign: 'center', fontSize: 8 }, 5: { halign: 'center', fontSize: 8 }, 6: { halign: 'center', fontSize: 8 } },

    })

    window.open(pdf.output('bloburl'));
  }


  function fecharModal1() {
    setAlteraFiltros({ ...modelFiltros })
    handleSearchTodos()
    setOpen1(false);
  }

  function fecharModal2() {
    setAlteraFiltros({ ...modelFiltros })
    handleSearchTodos()
    setOpen2(false);
  }

  async function preRelatorio(item: PreVendaSimplificado) {
    const response = await api.get(`/v1/pre-vendas/${item.id}`)
    const itemx: ConsultaContratos = response.data[0];

    relatorio(itemx)
  }

  async function preRelatorioREP(item: PreVendaSimplificado) {
    const response = await api.get(`/v1/pre-vendas/${item.id}`)
    const itemx: ConsultaContratos = response.data[0];

    relatorioREP(itemx)

  }

  function formatarData(data:string){
    let dataF = data.slice(8,10)+'/'+data.slice(5,7)+'/'+data.slice(0,4)
    return dataF
  }

  const relatorio = (item: ConsultaContratos) => {
    let nomeVendedor: string = ""
    let supervisor: string = ""
    let idVend: string = ""
    let idSupervisor: string = ""
    let cargoSup: string = ""
    let planoVendaDesc = item.planoVendaDescricao ? item.planoVendaDescricao.toString() : ""
    let dados: DadosPreVenda = JSON.parse(item.conteudo)
    let grupo = dados.grupo
    let dataExpedicaoF = formatarData(dados.dataExpedicao||'')
    if (grupo === "69")
      grupo = "EM FORMAÇÃO"
    // console.log(dados)
    console.log('Dados Itemmmm')
    console.log(item)
    const pdf = new jsPDF("p", "mm", "a4");
    api.get(`/v1/dados-usuario/${item.idVendedor}`).then((response) => {
      const vend: Usuario = response.data[0]
      console.log(vend)
      if (vend.tipoPessoa === "PESSOA_JURIDICA") {
        nomeVendedor = vend.nomePrincipal
        idVend = (vend.codigo || 0).toString()
      } else {
        nomeVendedor = (vend.nomePrincipal + ' ' + vend.nomeSecundario)
        idVend = (vend.id).toString()
      }
      if (vend.superiorId !== undefined) {
        api.get(`/v1/usuarios/${vend.superiorId}`).then((response2) => {
          const sup: Usuario = response2.data[0].dadoUsuario
          console.log(sup)
          if (sup.tipoPessoa === "PESSOA_JURIDICA") {
            supervisor = (sup.nomePrincipal)
            idSupervisor = (sup.codigo || 0).toString()
            cargoSup = (sup.cargo?.nome || "")
          } else {
            supervisor = (sup.nomePrincipal + ' ' + sup.nomeSecundario)
            idSupervisor = (sup.id).toString()
            cargoSup = (sup.cargo?.nome || "")
          }

          let codVend = dados.idVendedor ? dados.idVendedor.toString() : ""
          let prazo = dados.prazo ? dados.prazo.toString() : ""
          let contrato = item.idContrato ? item.idContrato.toString() : ""

          let codBem = dados.codB ? dados.codB.toString() : ""
          let seguros = dados.segVS === "X" ? "SIM" : "NÃO"

          const dataAtual = moment().format('DD/MM/YYYY HH:mm:ss')

          pdf.setFontSize(9);
          pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
          pdf.text(`Usuário: ${usuario?.dadoUsuario.nomePrincipal || ""} ${usuario?.dadoUsuario.nomeSecundario || ""}`, 200, 12, { align: 'right' });
          pdf.text(`Data emissão: ${dataAtual || ""} `, 200, 17, { align: 'right' });
          pdf.line(10, 25, 200, 25); // horizontal line
          pdf.setFontSize(13);
          pdf.setFont("arial", "bold")
          pdf.text("RELATÓRIO DE VENDA", 105, 30, { align: 'center' });
          pdf.line(10, 32, 200, 32);
          pdf.setFontSize(10);
          pdf.setFont("arial", "normal");
          pdf.setDrawColor(0);
          pdf.setFillColor(255, 255, 255);
          //quadro 1
          pdf.roundedRect(10, 34, 190, 44, 1, 1, "FD");
          //quadro 2
          pdf.roundedRect(10, 80, 190, 39, 1, 1, "FD");
          //quadro 3
          pdf.roundedRect(10, 121, 190, 24, 1, 1, "FD");
          //quadro 4
          pdf.roundedRect(10, 147, 190, 45, 1, 1, "FD");
          //quadro 5
          pdf.roundedRect(10, 194, 190, 45, 1, 1, "FD");
          //quadro 6
          pdf.roundedRect(10, 241, 190, 9, 1, 1, "FD");
          //quadro 7
          pdf.roundedRect(10, 252, 190, 26, 1, 1, "FD");



          pdf.setFont("arial", "bold")
          pdf.setFontSize(10);
          //quadro 1 titulos esquerda
          pdf.text("Grupo:", 15, 40);
          pdf.text(`Código ${cargoSup.toLowerCase() || ""}: `, 15, 47);
          pdf.text("Código vendedor:", 15, 54);
          pdf.text("Codigo funcionário:", 15, 61);
          pdf.text("Código Prazo:", 15, 68);
          pdf.text("Bem:", 15, 75);
          //quadro 1 titulos direita
          pdf.text("Número do contrato:", 100, 40);
          pdf.text(cargoSup.toLowerCase() || "", 100, 47);
          pdf.text("Vendedor:", 100, 54);
          pdf.text("Plano:", 100, 61);
          pdf.text("Código bem:", 100, 68);
          pdf.text("Valor bem:", 100, 75);
          //quadro 2 titulos esquerda
          pdf.text("CPF:", 15, 86);
          pdf.text("RG:", 15, 93);
          pdf.text("Data Expedição:", 15, 100);
          pdf.text("Naturalidade:", 15, 107);
          pdf.text("Data nascimento:", 15, 115);
          //quadro 2 titulos direita
          pdf.text("Nome:", 100, 86);
          pdf.text("Orgão emissor:", 100, 93);
          pdf.text("Estado:", 100, 100);
          pdf.text("Nacionalidade:", 100, 107);
          //quadro 3 titulos esquerda
          pdf.text("Estado civil", 15, 127);
          pdf.text("Profissão:", 15, 134);
          pdf.text("Celular:", 15, 141);
          //quadro 3 titulos direita
          pdf.text("Sexo:", 100, 127);
          pdf.text("Renda:", 100, 134);
          //quadro 4 titulos esquerda até 193
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.text("Endereço Residencial", 15, 152);
          pdf.setFontSize(10);
          pdf.setTextColor(0);
          pdf.text("Rua:", 15, 159);
          pdf.text("Apartamento:", 15, 165);
          pdf.text("Bairro:", 15, 171);
          pdf.text("CEP:", 15, 177);
          pdf.text("E-mail:", 15, 183);
          pdf.text("Telefone Fixo:", 15, 189);
          //quadro 4 titulos direita
          pdf.text("Número:", 100, 159);
          pdf.text("Complemento:", 100, 165);
          pdf.text("Cidade:", 100, 171);
          pdf.text("Estado:", 100, 177);
          //quadro 5 titulos esquerda até 193
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.text("Endereço Comercial", 15, 199);
          pdf.setFontSize(10);
          pdf.setTextColor(0);
          pdf.text("Rua:", 15, 206);
          pdf.text("Apartamento:", 15, 212);
          pdf.text("Bairro:", 15, 218);
          pdf.text("CEP:", 15, 224);
          pdf.text("E-mail:", 15, 230);
          pdf.text("Telefone Fixo:", 15, 236);
          //quadro 5 titulos direita
          pdf.text("Número:", 100, 206);
          pdf.text("Complemento:", 100, 211);
          pdf.text("Cidade:", 100, 217);
          pdf.text("Estado:", 100, 223);
          //quadro 6 titulos esquerda
          pdf.text("Pai:", 15, 247);
          //quadro 6 titulos direita
          pdf.text("Mãe:", 100, 247);
          //quadro 7 titulos esquerda
          pdf.text("Data Adesão", 15, 258);
          pdf.text("Valor recebido:", 15, 265);
          pdf.text("Endereço cobrança:", 15, 272);
          //quadro 7 titulos direita
          pdf.text("Optante seguro:", 100, 258);
          pdf.text("Valor 1ª parcela:", 100, 265);
          pdf.text("Tipo assinatura:", 100, 272);

          pdf.setFont("times", "normal")
          pdf.setFontSize(10);
          //quadro 1 Dados Variados esquerda
          pdf.text((grupo) || "", 28, 40);
          pdf.text(`${idSupervisor}` || "", (40 + (cargoSup.length || 30)), 47);
          pdf.text(idVend || "", 42, 54);
          pdf.text(idVend || "", 46, 61);
          pdf.text(prazo || "", 38, 68);
          pdf.text(dados.descricaoBem || "", 24, 75);
          //pdf.text(dados.descricaoBem||"", 24, 82);
          //quadro 1 titulos direita
          pdf.text(contrato || "", 133, 40);
          pdf.text(supervisor || "", (110 + (cargoSup.length || 30) * 2), 47);
          pdf.text(nomeVendedor || "", 117, 54);
          pdf.text(item.planoVendaDescricao || "", 112, 61);
          pdf.text(codBem || "", 121, 68);
          pdf.text(dados.valorCreditoF || "", 119, 75);
          //pdf.text(dados.valorCreditoF||"", 119, 82);
          //quadro 2 titulos esquerda
          pdf.text(dados.documentoFormatado || "", 25, 86);
          pdf.text(dados.documento2 || "", 23, 93);
          pdf.text(dataExpedicaoF || "", 42, 100);
          pdf.text(dados.naturalCidade || "", 38, 107);
          pdf.text(dados.dataNascimentoF || "", 43, 115);
          //quadro 2 titulos direita
          pdf.text(dados.nomeCliente || "", 111, 86);
          pdf.text(dados.orgEm || "", 125, 93);
          pdf.text(dados.naturalUF || "", 113, 100);
          pdf.text(dados.nacionalidade || "", 124, 107);
          //quadro 3 titulos esquerda
          pdf.text(dados.estadoCivil || "", 34, 127);
          pdf.text(dados.profissao || "", 32, 134);
          pdf.text(`${dados.telefone1 || ""}, ${dados.telefone2 || ""}`, 30, 141);
          //quadro 3 titulos direita
          pdf.text(dados.sexo || "", 110, 127);
          pdf.text(dados.rendaFormatado || "", 112, 134);
          //quadro 4 titulos esquerda até 193
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.setFontSize(10);
          pdf.setTextColor(0);
          pdf.text(dados.endereco?.substring(0, 35) || "", 24, 159);
          pdf.text(dados.apto || "", 38, 165);
          pdf.text(dados.bairro || "", 27, 171);
          pdf.text(dados.cep || "", 25, 177);
          pdf.text(`${dados.email1 || ""}, ${dados.email2 || ""}`, 27, 183);
          pdf.text(`${dados.telefone1 || ""}, ${dados.telefone2 || ""}`, 38, 189);
          //quadro 4 titulos direita
          pdf.text(dados.numero || "", 117, 159);
          pdf.text(dados.complemento || "", 128, 165);
          pdf.text(dados.cidade || "", 115, 171);
          pdf.text(dados.uf || "", 115, 177);
          //quadro 5 titulos esquerda até 193
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.setFontSize(10);
          pdf.setTextColor(0);
          pdf.text(dados.enderecoCom?.substring(0, 33) || "", 24, 206);
          pdf.text(dados.aptoCom || "", 38, 212);
          pdf.text(dados.bairroCom || "", 27, 218);
          pdf.text(dados.cepCom || "", 25, 224);
          pdf.text(`${dados.emailCom1 || ""}, ${dados.emailCom2 || ""}`, 27, 230);
          pdf.text(`${dados.telefoneCom2 || ""}, ${dados.telefoneCom2 || ""}`, 38, 236);
          //quadro 5 titulos direita
          pdf.text(dados.numeroCom || "", 117, 206);
          pdf.text(dados.complemento || "", 128, 212);
          pdf.text(dados.cidadeCom || "", 115, 218);
          pdf.text(dados.UFE || "", 115, 224);
          //quadro 6 titulos esquerda
          pdf.text(dados.nomePai || "", 23, 247);
          //quadro 6 titulos direita
          pdf.text(dados.nomeMae || "", 110, 247);
          //quadro 7 titulos esquerda
          pdf.text(dados.dataAdesaoF || "", 36, 258);
          pdf.text("R$ 0,00", 39, 265);
          pdf.text(dados.enderecoCobranca || "", 47, 272);
          //quadro 7 titulos direita
          pdf.text(seguros || "", 126, 258);
          pdf.text(dados.valorParcelaFormatado || "", 127, 265);
          pdf.text(dados.tipoAssinatura || "", 126, 272);


          pdf.text("Groscon Administradora de Consórcios LTDA", 200, 285, { align: 'right' });
          pdf.setDrawColor(0, 0, 0);
          pdf.line(10, 280, 200, 280);
          window.open(pdf.output('bloburl'));
        })
      } else {

        let codVend = dados.idVendedor ? dados.idVendedor.toString() : ""
        let prazo = dados.prazo ? dados.prazo.toString() : ""
        let contrato = item.idContrato ? item.idContrato.toString() : ""
        let planoVendaDesc = dados.planoVendaDescricao ? dados.planoVendaDescricao.toString() : ""
        let codBem = dados.codB ? dados.codB.toString() : ""
        let seguros = dados.segVS === "X" ? "SIM" : "NÃO"

        const dataAtual = moment().format('DD/MM/YYYY HH:mm:ss')
        //const string = renderToString(<Prints />); 

        pdf.setFontSize(9);
        pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
        pdf.text(`Usuário: ${usuario?.dadoUsuario.nomePrincipal || ""} ${usuario?.dadoUsuario.nomeSecundario || ""}`, 200, 12, { align: 'right' });
        pdf.text(`Data emissão: ${dataAtual || ""} `, 200, 17, { align: 'right' });
        //pdf.table(23, 58, generateData(100), headers ,{ autoSize: true });
        pdf.line(10, 25, 200, 25); // horizontal line
        pdf.setFontSize(13);
        pdf.setFont("arial", "bold")
        pdf.text("RELATÓRIO DE VENDA", 105, 30, { align: 'center' });
        pdf.line(10, 32, 200, 32);
        //pdf.table(23, 58, generateData(1), headers ,{ autoSize: true });
        //window.open(pdf.output('datauristring'));
        pdf.setFontSize(10);
        pdf.setFont("arial", "normal");
        pdf.setDrawColor(0);
        pdf.setFillColor(255, 255, 255);
        //quadro 1
        pdf.roundedRect(10, 34, 190, 44, 1, 1, "FD");
        //quadro 2
        pdf.roundedRect(10, 80, 190, 39, 1, 1, "FD");
        //quadro 3
        pdf.roundedRect(10, 121, 190, 24, 1, 1, "FD");
        //quadro 4
        pdf.roundedRect(10, 147, 190, 45, 1, 1, "FD");
        //quadro 5
        pdf.roundedRect(10, 194, 190, 45, 1, 1, "FD");
        //quadro 6
        pdf.roundedRect(10, 241, 190, 9, 1, 1, "FD");
        //quadro 7
        pdf.roundedRect(10, 252, 190, 26, 1, 1, "FD");



        pdf.setFont("arial", "bold")
        pdf.setFontSize(10);
        //quadro 1 titulos esquerda
        pdf.text("Grupo:", 15, 40);
        pdf.text(`Código ${cargoSup || ""}:`, 15, 47);
        pdf.text("Código vendedor:", 15, 54);
        pdf.text("Codigo funcionário:", 15, 61);
        pdf.text("Código Prazo:", 15, 68);
        pdf.text("Bem:", 15, 75);
        //pdf.text("Bem:", 15, 82);
        //quadro 1 titulos direita
        pdf.text("Número do contrato:", 100, 40);
        pdf.text(cargoSup || "", 100, 47);
        pdf.text("Vendedor:", 100, 54);
        pdf.text("Plano de venda:", 100, 61);
        pdf.text("Código bem:", 100, 68);
        pdf.text("Valor bem:", 100, 75);
        //quadro 2 titulos esquerda
        pdf.text("CPF:", 15, 86);
        pdf.text("RG:", 15, 93);
        pdf.text("Data Expedição:", 15, 100);
        pdf.text("Naturalidade:", 15, 107);
        pdf.text("Data nascimento:", 15, 115);
        //quadro 2 titulos direita
        pdf.text("Nome:", 100, 86);
        pdf.text("Orgão emissor:", 100, 93);
        pdf.text("Estado:", 100, 100);
        pdf.text("Nacionalidade:", 100, 107);
        //quadro 3 titulos esquerda
        pdf.text("Estado civil", 15, 127);
        pdf.text("Profissão:", 15, 134);
        pdf.text("Celular:", 15, 141);
        //quadro 3 titulos direita
        pdf.text("Sexo:", 100, 127);
        pdf.text("Renda:", 100, 134);
        //quadro 4 titulos esquerda até 193
        pdf.setFontSize(11);
        pdf.setTextColor(100);
        pdf.text("Endereço Residencial", 15, 152);
        pdf.setFontSize(10);
        pdf.setTextColor(0);
        pdf.text("Rua:", 15, 159);
        pdf.text("Apartamento:", 15, 165);
        pdf.text("Bairro:", 15, 171);
        pdf.text("CEP:", 15, 177);
        pdf.text("E-mail:", 15, 183);
        pdf.text("Telefone Fixo:", 15, 189);
        //quadro 4 titulos direita
        pdf.text("Número:", 100, 159);
        pdf.text("Complemento:", 100, 165);
        pdf.text("Cidade:", 100, 171);
        pdf.text("Estado:", 100, 177);
        //quadro 5 titulos esquerda até 193
        pdf.setFontSize(11);
        pdf.setTextColor(100);
        pdf.text("Endereço Comercial", 15, 199);
        pdf.setFontSize(10);
        pdf.setTextColor(0);
        pdf.text("Rua:", 15, 206);
        pdf.text("Apartamento:", 15, 212);
        pdf.text("Bairro:", 15, 218);
        pdf.text("CEP:", 15, 224);
        pdf.text("E-mail:", 15, 230);
        pdf.text("Telefone Fixo:", 15, 236);
        //quadro 5 titulos direita
        pdf.text("Número:", 100, 206);
        pdf.text("Complemento:", 100, 211);
        pdf.text("Cidade:", 100, 217);
        pdf.text("Estado:", 100, 223);
        //quadro 6 titulos esquerda
        pdf.text("Pai:", 15, 247);
        //quadro 6 titulos direita
        pdf.text("Mãe:", 100, 247);
        //quadro 7 titulos esquerda
        pdf.text("Data Adesão", 15, 258);
        pdf.text("Valor recebido:", 15, 265);
        pdf.text("Endereço cobrança:", 15, 272);
        //quadro 7 titulos direita
        pdf.text("Optante seguro:", 100, 258);
        pdf.text("Valor 1ª parcela:", 100, 265);
        pdf.text("Tipo assinatura:", 100, 272);

        pdf.setFont("times", "normal")
        pdf.setFontSize(10);
        //quadro 1 Dados Variados esquerda
        pdf.text(grupo || "", 28, 40);
        pdf.text(idSupervisor || "", (40 + (cargoSup.length || 30) * 2), 47);
        pdf.text(idVend || "", 42, 54);
        pdf.text(idVend || "", 46, 61);
        pdf.text(prazo || "", 38, 68);
        pdf.text(dados.descricaoBem || "", 24, 75);
        //pdf.text(dados.descricaoBem||"", 24, 82);
        //quadro 1 titulos direita
        pdf.text(contrato || "", 133, 40);
        pdf.text(supervisor || "", (110 + (cargoSup.length || 30) * 2), 47);
        pdf.text(nomeVendedor || "", 117, 54);
        pdf.text(item.planoVendaDescricao || "", 126, 61);
        pdf.text(codBem || "", 121, 68);
        pdf.text(dados.valorCreditoF || "", 119, 75);
        //pdf.text(dados.valorCreditoF||"", 119, 82);
        //quadro 2 titulos esquerda
        pdf.text(dados.documentoFormatado || "", 25, 86);
        pdf.text(dados.documento2 || "", 23, 93);
        pdf.text(dataExpedicaoF || "", 42, 100);
        pdf.text(dados.naturalCidade || "", 38, 107);
        pdf.text(dados.dataNascimentoF || "", 43, 115);
        //quadro 2 titulos direita
        pdf.text(dados.nomeCliente || "", 111, 86);
        pdf.text(dados.orgEm || "", 125, 93);
        pdf.text(dados.naturalUF || "", 113, 100);
        pdf.text(dados.nacionalidade || "", 124, 107);
        //quadro 3 titulos esquerda
        pdf.text(dados.estadoCivil || "", 34, 127);
        pdf.text(dados.profissao || "", 32, 134);
        pdf.text(`${dados.telefone1 || ""}, ${dados.telefone2 || ""}`, 30, 141);
        //quadro 3 titulos direita
        pdf.text(dados.sexo || "", 110, 127);
        pdf.text(dados.rendaFormatado || "", 112, 134);
        //quadro 4 titulos esquerda até 193
        pdf.setFontSize(11);
        pdf.setTextColor(100);
        pdf.setFontSize(10);
        pdf.setTextColor(0);
        pdf.text(dados.endereco || "", 24, 159);
        pdf.text(dados.apto || "", 38, 165);
        pdf.text(dados.bairro || "", 27, 171);
        pdf.text(dados.cep || "", 25, 177);
        pdf.text(`${dados.email1 || ""}, ${dados.email2 || ""}`, 27, 183);
        pdf.text(`${dados.telefone1 || ""}, ${dados.telefone2 || ""}`, 38, 189);
        //quadro 4 titulos direita
        pdf.text(dados.numero || "", 117, 159);
        pdf.text(dados.complemento || "", 128, 165);
        pdf.text(dados.cidade || "", 115, 171);
        pdf.text(dados.uf || "", 115, 177);
        //quadro 5 titulos esquerda até 193
        pdf.setFontSize(11);
        pdf.setTextColor(100);
        pdf.setFontSize(10);
        pdf.setTextColor(0);
        pdf.text(dados.enderecoCom || "", 24, 206);
        pdf.text(dados.aptoCom || "", 38, 212);
        pdf.text(dados.bairroCom || "", 27, 218);
        pdf.text(dados.cepCom || "", 25, 224);
        pdf.text(`${dados.emailCom1 || ""}, ${dados.emailCom2 || ""}`, 27, 230);
        pdf.text(`${dados.telefoneCom2 || ""}, ${dados.telefoneCom2 || ""}`, 38, 236);
        //quadro 5 titulos direita
        pdf.text(dados.numeroCom || "", 117, 206);
        pdf.text(dados.complemento || "", 128, 212);
        pdf.text(dados.cidadeCom || "", 115, 218);
        pdf.text(dados.UFE || "", 115, 224);
        //quadro 6 titulos esquerda
        pdf.text(dados.nomePai || "", 23, 247);
        //quadro 6 titulos direita
        pdf.text(dados.nomeMae || "", 110, 247);
        //quadro 7 titulos esquerda
        pdf.text(dados.dataAdesaoF || "", 36, 258);
        pdf.text("R$ 0,00", 39, 265);
        pdf.text(dados.enderecoCobranca || "", 47, 272);
        //quadro 7 titulos direita
        pdf.text(seguros || "", 126, 258);
        pdf.text(dados.valorParcelaFormatado || "", 127, 265);
        pdf.text(dados.tipoAssinatura || "", 126, 272);


        pdf.text("Groscon Administradora de Consórcios LTDA", 200, 285, { align: 'right' });
        pdf.setDrawColor(0, 0, 0);
        pdf.line(10, 280, 200, 280);

        window.open(pdf.output('bloburl'));
      }

    })
  };

  const relatorioREP = (item: ConsultaContratos) => {
    let dados: DadosPreVenda = JSON.parse(item.conteudo)
    const dataAtual = moment().format('DD/MM/YYYY HH:mm:ss')
    let grupo = dados.grupo
    if (grupo === "69")
      grupo = "EM FORMAÇÃO"
    //const string = renderToString(<Prints />);
    let continua: boolean = true;
    let continua2: boolean = true;
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.setFontSize(9);
    pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
    pdf.text(`USUÁRIO: ${nome?.toUpperCase()}`, 200, 8, { align: 'right' });
    // console.log(dados)
    contatos.map(function (item) {
      if (item.tipoContato !== "EMAIL" && continua) {
        continua = false;
        return pdf.text(`TELEFONE ${item.valor || '(16) 3707-5500'}`, 200, 12.5, { align: 'right' });
      }
      if (item.tipoContato === "EMAIL" && continua2) {
        continua2 = false;
        return pdf.text(`E-MAIL: ${item.valor.toUpperCase()}`, 200, 17, { align: 'right' });
      }
    })
    if (continua)
      pdf.text(`TELEFONE (16) 3707-5500'}`, 200, 13, { align: 'right' });

    pdf.text(`DATA : ${dataAtual}`, 200, 22, { align: 'right' });
    pdf.line(10, 25, 200, 25); // horizontal line
    pdf.setFontSize(15);
    pdf.setFont("arial", "bold")
    pdf.text(`DADOS VENDA - CONTRATO Nº ${item.idContrato.toString() || ""}`, 105, 33, { align: 'center' });
    pdf.line(10, 37, 200, 37);

    pdf.setFontSize(10);
    pdf.setFont("times", "normal");


    pdf.setDrawColor(0);
    pdf.setFillColor(255, 255, 255);
    pdf.roundedRect(10, 40, 190, 122, 1, 1, "FD");
    pdf.setDrawColor(0);
    pdf.setFillColor(190, 190, 190)
    pdf.setDrawColor(190, 190, 190)
    pdf.roundedRect(15, 50, 70, 20, 2, 2, "FD");
    pdf.roundedRect(87, 50, 110, 20, 2, 2, "FD");
    pdf.roundedRect(15, 72, 140, 20, 2, 2, "FD");
    pdf.roundedRect(157, 72, 40, 20, 2, 2, "FD");
    pdf.roundedRect(15, 94, 98, 20, 2, 2, "FD");
    pdf.roundedRect(115, 94, 40, 20, 2, 2, "FD");
    pdf.roundedRect(157, 94, 40, 20, 2, 2, "FD");
    pdf.roundedRect(15, 116, 40, 20, 2, 2, "FD");
    pdf.roundedRect(57, 116, 98, 20, 2, 2, "FD");
    pdf.roundedRect(157, 116, 40, 20, 2, 2, "FD");
    pdf.roundedRect(15, 138, 182, 20, 2, 2, "FD");


    //Titulos
    pdf.setFontSize(11);
    pdf.setFont("times", "bold")
    pdf.text("INFORMAÇÕES DO CONSORCIADO", 105, 46, { align: 'center' });
    pdf.setFontSize(10);
    pdf.setFont("times", "normal");
    pdf.text("CPF/CNPJ", 48, 56, { align: 'center' });
    pdf.text("Nome", 140, 56, { align: 'center' });

    pdf.text("Endereço", 85, 78, { align: 'center' });
    pdf.text("Número", 177, 78, { align: 'center' });

    pdf.text("Bairro", 64, 100, { align: 'center' });
    pdf.text("Apto", 135, 100, { align: 'center' });
    pdf.text("Complemento", 177, 100, { align: 'center' });

    pdf.text("Estado", 35, 122, { align: 'center' });
    pdf.text("Cidade", 106, 122, { align: 'center' });
    pdf.text("CEP", 177, 122, { align: 'center' });

    pdf.text("E-MAIL", 106, 144, { align: 'center' });

    pdf.setFontSize(14);
    pdf.setFont("times", "normal")
    pdf.text((dados.endereco || ""), 85, 85, { align: 'center' });
    pdf.text(`${dados.numero || ""}`, 177, 85, { align: 'center' });

    pdf.text((dados.documentoFormatado || 0).toString(), 48, 63, { align: 'center' });
    pdf.text(`${dados.nomeCliente}`, 140, 63, { align: 'center' });

    pdf.text((dados.bairro || ""), 64, 107, { align: 'center' });
    pdf.text(`${dados.apto || ""}`, 135, 107, { align: 'center' });
    pdf.text((dados.complemento || ""), 177, 107, { align: 'center' });

    pdf.text((dados.uf || ""), 35, 129, { align: 'center' });
    pdf.text(`${dados.cidade || ""}`, 106, 129, { align: 'center' });
    pdf.text((dados.cep || ""), 177, 129, { align: 'center' });

    pdf.text((dados.email1?.toUpperCase() || ""), 106, 151, { align: 'center' });



    pdf.setFontSize(10);
    pdf.setFont("times", "normal");


    pdf.setDrawColor(0);
    pdf.setFillColor(255, 255, 255);
    pdf.roundedRect(10, 165, 190, 100, 1, 1, "FD");
    pdf.setDrawColor(0);
    pdf.setFillColor(190, 190, 190)
    pdf.setDrawColor(190, 190, 190)
    pdf.roundedRect(15, 175, 34.8, 20, 2, 2, "FD");
    pdf.roundedRect(51.8, 175, 34.8, 20, 2, 2, "FD");
    pdf.roundedRect(88.6, 175, 34.8, 20, 2, 2, "FD");
    pdf.roundedRect(125.4, 175, 34.8, 20, 2, 2, "FD");
    pdf.roundedRect(162.2, 175, 34.8, 20, 2, 2, "FD");
    pdf.roundedRect(15, 197, 50, 20, 2, 2, "FD");
    pdf.roundedRect(67, 197, 130, 20, 2, 2, "FD");
    pdf.roundedRect(15, 219, 59.33, 20, 2, 2, "FD");
    pdf.roundedRect(76.33, 219, 59.33, 20, 2, 2, "FD");
    pdf.roundedRect(137.66, 219, 59.33, 20, 2, 2, "FD");
    pdf.roundedRect(15, 241, 59.33, 20, 2, 2, "FD");
    pdf.roundedRect(76.33, 241, 120.66, 20, 2, 2, "FD");



    //Titulos
    pdf.setFontSize(11);
    pdf.setFont("times", "bold")
    pdf.text("INFORMAÇÕES DO CONTRATO", 105, 171, { align: 'center' });
    pdf.setFontSize(10);
    pdf.setFont("times", "normal");

    pdf.text("Data Venda", 32.4, 181, { align: 'center' });
    pdf.text("Grupo", 69.3, 181, { align: 'center' });
    pdf.text("Prazo", 106.1, 181, { align: 'center' });
    pdf.text("Taxa Adm Total", 142.9, 181, { align: 'center' });
    pdf.text("Adesão", 179.7, 181, { align: 'center' });

    pdf.text("Cod. Bem", 40, 203, { align: 'center' });
    pdf.text("Descrição Bem", 132, 203, { align: 'center' });

    pdf.text("Valor Crédito", 44.66, 225, { align: 'center' });
    pdf.text("Parcela", 106, 225, { align: 'center' });
    pdf.text("Primeira Assembleia", 167.33, 225, { align: 'center' });

    pdf.text("Redução", 44.66, 247, { align: 'center' });
    pdf.text("Nome Vendedor", 132, 247, { align: 'center' });


    pdf.setFontSize(14);
    pdf.setFont("times", "normal")
    pdf.text((dados.dataAdesaoF || ""), 32.4, 188, { align: 'center' });
    pdf.text(`${grupo || ""}`, 69.3, 188, { align: 'center' });
    pdf.text(`${dados.prazo?.toString() || ""} meses`, 106.1, 188, { align: 'center' });
    pdf.text(`${dados.taxaAdministracao?.toString() || ""}%`, 142.9, 188, { align: 'center' });
    pdf.text(`${dados.adesao?.toString() || ""}%`, 179.7, 188, { align: 'center' });

    pdf.text((dados.codB || 0).toString(), 40, 210, { align: 'center' });
    pdf.text(`${dados.descricaoBem}`, 132, 210, { align: 'center' });

    pdf.text((dados.valorCreditoF || ""), 44.66, 232, { align: 'center' });
    pdf.text(`${dados.contratarSeguro === 0 ? dados.valorParcelaFormatado : dados.valorParcelaComSeguroFormatado || ""}`, 106, 232, { align: 'center' });
    pdf.text((formatData(dados.primeiraAssembleia || "") || ""), 167.33, 232, { align: 'center' });

    pdf.text(`${dados.reducao?.toString() || "0"}%`, 44.66, 254, { align: 'center' });
    pdf.text(`${dados.nomeVendedor?.toString().toUpperCase() || ""}`, 132, 254, { align: 'center' });

    pdf.setFontSize(10);
    pdf.text("Groscon Administradora de Consórcios LTDA", 200, 285, { align: 'right' });
    pdf.setDrawColor(0, 0, 0);
    pdf.line(10, 280, 200, 280);



    window.open(pdf.output('bloburl'));
  };

  const onDrop = (acceptedFiles: any) => {

    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()


      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result
      }
      reader.readAsArrayBuffer(file)
      dispararUpload(file)
    })
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  async function dispararUpload(file: any) {
    const arquivo = new FormData()
    arquivo.append('arquivo', file, file.name)
    addToast({
      type: 'info',
      title: 'Enviando Arquivo',
      description: `Aguarde!`
    });
    const response = await api.post(`/v1/vendas/${itemCancelamento?.idVenda}/upload`, arquivo)
    addToast({
      type: 'success',
      title: 'Arquivo Salvo',
      description: ``
    });

    preVenda()

    listagemArquivo(itemCancelamento?.idVenda || 0)

  }

  async function deletaArquivo(item: UploadLista) {
    const response = await api.delete(`/v1/vendas/anexos/${item.id}`)
    listagemArquivo(item.vendaId)
    addToast({
      type: 'error',
      title: 'Deletar arquivo',
      description: `Arquivo deletado com sucesso!`
    });

    preVenda()

  }

  async function handleChangeSelectedCargo(e: ChangeEvent<HTMLSelectElement>) {
    const valor = e.target.value
    const campo = e.target.name
    const response = await api.get(`v1/pre-vendas/${e.target.id}`)
    let item: ConsultaContratos = response.data[0]
    if (item.anexos) {
      delete item.anexos
    }
    const envio = await api.put(`v1/pre-vendas/${e.target.id}`,
      {
        ...item,
        [campo]: valor
      }
    )

    if (envio.status === 200) {
      preVenda()
    }
  }

  return (
    <>
      <Header />
      <Container >
        <Tabelas>

          <Typography>
            <div className='title'><h2>Consulta Contrato</h2></div>
            {dadosVenda.codB === 0 ? <>
              <div className="tabela">
                <Form className="form-border" onSubmit={onSubmit}>
                  <Row between>
                    <Col min='320px'>
                      <div className="inputNovo">
                        <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }}>
                          <TextField
                            className='inputNovo'
                            id="standard-required"
                            label="Nome do Cliente"
                            variant="standard"
                            name='nomeCliente'
                            value={filtros.nomeCliente}
                            onChange={handleChangeBemInput1}
                          />
                        </FormControl>
                      </div>
                    </Col>
                    <Col min='320px'>
                      <div className="inputNovo">
                        <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }}>
                          <TextField
                            className='inputNovo'
                            id="standard-required"
                            label="Número do Contrato"
                            variant="standard"
                            name='numeroContrato'
                            value={filtros.numeroContrato > 0 ? filtros.numeroContrato : ""}
                            onChange={handleChangeBemInput1}
                          />
                        </FormControl>
                      </div>
                    </Col>
                  </Row>

                  <Row between style={{ marginTop: 23 }}>
                    <Col min='320px'>
                      <div className="inputNovo">
                        <Form.Group className="select">
                          <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Vendedor
                          </InputLabel>
                          <NativeSelect
                            style={{ width: "100%" }}
                            className="inputNovo"
                            value={filtros.vendedor}
                            onChange={handleChangeVendedor}
                            defaultValue="0"
                            inputProps={{
                              name: 'vendedor',
                              id: 'uncontrolled-native',
                            }}
                          >
                            <option id="0" value="0">Todos</option>
                            <option id={usuario?.dadoUsuario.id.toString()} value={usuario?.dadoUsuario.id}>{usuario?.dadoUsuario.nomePrincipal} {usuario?.dadoUsuario.nomeSecundario}</option>
                            {subordinados?.map(function (item) {
                              if (usuario?.dadoUsuario.cargo?.id !== 3) {
                                if (item.nomePrincipal !== undefined)
                                  return (
                                    <option value={item.dadoUsuarioId}>
                                      {item.nomePrincipal} {item.nomeSecundario}
                                    </option>
                                  )
                              } else {
                                if (item.nomePrincipal !== undefined)
                                  return (
                                    <>
                                      <option value={item.id}>
                                        {item.nomePrincipal} {item.nomeSecundario}
                                      </option>
                                    </>
                                  )
                              }
                            }
                            )}

                          </NativeSelect>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col min='220px' max='320px'>
                      <div className="inputNovo">
                        <Form.Group className="select">
                          <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Status
                          </InputLabel>
                          <NativeSelect
                            style={{ width: "100%" }}
                            className="inputNovo"
                            value={filtros.status}
                            onChange={handleChangeVendedor}
                            inputProps={{
                              name: 'status',
                              id: 'uncontrolled-native',
                            }}
                          >
                            <option value="TODOS">TODOS</option>
                            <option value="APROVADO">APROVADO</option>
                            <option value="PENDENTE">PENDENTE</option>
                            <option value="CONCLUIDO">CONCLUIDO</option>
                            <option value="CANCELADO">CANCELADO</option>
                          </NativeSelect>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col min='140px' max='220px'>
                      <div className='datePick'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                          <DesktopDatePicker
                            label="Data Inicio"
                            inputFormat="dd/MM/yyyy"
                            value={age1}
                            onChange={handleChangeDate}
                            className="teste"
                            renderInput={(params) => <TextField {...params} name="dataInicio"
                              sx={{ maxWidth: 220, alignItems: "right" }}
                              InputLabelProps={{ shrink: filtros.dataInicio !== "" ? true : false }}
                            />
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    </Col>
                    <Col min='140px' max='220px'>
                      <div className='datePick'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                          <DesktopDatePicker
                            label="Data Fim"
                            inputFormat="dd/MM/yyyy"
                            value={age2}
                            onChange={handleChangeDate2}
                            className="teste"

                            renderInput={(params) => <TextField {...params}
                              sx={{ maxWidth: 220, alignItems: "right" }}
                            //InputLabelProps={{ shrink: DadosVenda.dataNascimento !== "" ? true : false }}
                            />
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    </Col>
                  </Row>

                  <div className="botaoPesquisar" style={{ marginTop: "20px" }}>
                    <Button variant="primary" className="btnPesquisar" onMouseDown={handleSearchTodos} style={{ marginLeft: "15" }}>
                      <Typography variant="button" gutterBottom> Pesquisar</Typography>
                    </Button>
                    <Button variant="primary" className="btnPesquisar" onClick={limparTodos} style={{ marginRight: "15", marginBottom: "15" }}>
                      <Typography variant="button" gutterBottom>LIMPAR</Typography>
                    </Button>
                  </div>

                </Form>
              </div>
              <div className="tabela3">
                {dadosVenda.visualizaBens === true ? <>
                  {!dadosVenda.bloqueado ? <>

                    {dadosVenda.sequenciaAgrupamento === 0 ?
                      <div style={{
                        maxWidth: '1100px',
                        width: '100%',
                        fontSize: '13px',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                      }}>
                        <Tabelas1>
                          <Table responsive className="table-overflow-auto">
                            <thead>
                              <tr>
                                <th className="ac tamanhoAC">Nome Cliente</th>
                                <th className="ac1" style={{ cursor: 'pointer', wordBreak: 'break-all' }} onClick={ordenarContrato}>Número do Contrato {ordenacaoContrato === 1 ? <FiArrowUp></FiArrowUp> : <FiArrowDown></FiArrowDown>}</th>
                                <th className="ac">Vendedor</th>
                                <th className="ac1" style={{ cursor: 'pointer' }} onClick={ordenarData}>Data Cadastro {ordenacaoData === 0 ? <FiArrowUp></FiArrowUp> : <FiArrowDown></FiArrowDown>}</th>
                                <th className="ac" style={{ minWidth: "40px" }}>Status</th>
                                <th className="ac">Pós-Venda</th>
                                <th className="ac">{window.screen.width > 600 ? "Conclusão" : ""}</th>
                                <th className="ac quebraLinha">Opções</th>
                              </tr>
                            </thead>
                            <tbody>

                              {_DATA.currentData().map(item => {
                                let dataCad = new Date(moment(item.dataCriacao).format())
                                let dataCad2 = new Date(item.dataCriacao)
                                let vencimento = moment(item.dataCriacao)
                                vencimento.add(1, 'days')
                                if (vencimento.isoWeekday() === 6) {
                                  vencimento.add(2, 'days')
                                } else if (vencimento.isoWeekday() === 7) {
                                  vencimento.add(1, 'days')
                                }
                                vencimento.hour(23)
                                vencimento.minute(59)
                                vencimento.second(59)

                                let vencimentoF = new Date(vencimento.format())

                                dataCad2.setDate(dataCad2.getDate() + 5)
                                let dataAtual = Date.now()
                                let hoje = new Date(dataAtual)
                                let dataCadF = (dataCad.toLocaleDateString('pt-BR', { timeZone: 'UTC' }))
                                let perc = parseFloat(((100 / 7) * item.etapa).toFixed(2))
                                return (
                                  <>
                                    <tr className="ac tamanhoAC">
                                      <td className="ac tamanhoAC" style={{ cursor: "pointer" }} onClick={() => testeEnvio(item)} ><Typography noWrap variant="body2">{item.nomeCliente}</Typography></td>
                                      <td className="ac" style={{ cursor: "pointer" }} onClick={() => testeEnvio(item)}>{item.idContrato}</td>
                                      <td className="ac" style={{ cursor: "pointer" }} onClick={() => testeEnvio(item)}><Typography noWrap variant="body2">{item.nomeVendedor?.toUpperCase()}</Typography></td>
                                      <td className="ac" style={{ cursor: "pointer" }} onClick={() => testeEnvio(item)}>{dataCadF}</td>
                                      {usuario?.dadoUsuario.cargo?.id === 3 ?
                                        <td className="ac tamanhoAC" style={{ cursor: "pointer", minWidth: "30px" }}>
                                          <Form.Group className="ac">
                                            <NativeSelect
                                              className="inputNovo teste"
                                              defaultValue={30}
                                              onChange={handleChangeSelectedCargo}
                                              value={item.status}
                                              inputProps={{
                                                name: 'status',
                                                id: item.id.toString(),
                                              }}
                                            >
                                              <option className="ac" id="PENDENTE" value="PENDENTE">PENDENTE</option>
                                              <option className="ac" id="CONCLUIDO" value="CONCLUIDO">CONCLUIDO</option>
                                              <option className="ac" id="CANCELADO" value="CANCELADO">CANCELADO</option>
                                              <option className="ac" id="APROVADO" value="APROVADO">APROVADO</option>


                                            </NativeSelect>
                                          </Form.Group>
                                        </td>
                                        :
                                        <td className="ac" style={{ cursor: "pointer" }} onClick={() => testeEnvio(item)}>{item.status}</td>}
                                      {usuario?.dadoUsuario.cargo?.id === 3 ?
                                        <td className="ac" style={{ cursor: "pointer" }}>
                                          <Form.Group className="select">
                                            <NativeSelect
                                              className="inputNovo teste"
                                              defaultValue={30}
                                              onChange={handleChangeSelectedCargo}
                                              value={item.posVenda}
                                              inputProps={{
                                                name: 'posVenda',
                                                id: item.id.toString()
                                              }}
                                            >
                                              <option className="ac" id="PENDENTE" value="PENDENTE">PENDENTE</option>
                                              <option className="ac" id="CONCLUIDO" value="CONCLUIDO">CONCLUIDO</option>
                                              <option className="ac" id="CANCELADO" value="CANCELADO">CANCELADO</option>
                                            </NativeSelect>
                                          </Form.Group>
                                        </td>
                                        :
                                        <td className="ac" style={{ cursor: "pointer" }} onClick={() => testeEnvio(item)}>{item.posVenda}</td>
                                      }
                                      <td className="ac tamanhoAC"><CircularProgressWithLabel size={35} variant="determinate" style={{ marginTop: '2x' }} value={item.idContrato ? 100 : perc} /></td>
                                      <td className="ac1 quebraLinha">
                                        <Tooltip placement="top" arrow title={item.tipoAssinatura === "DIGITAL" ? "Assinatura Digital" : "Assinatura Manual"}>
                                          <button disabled={item.status === "CANCELADO" ? true : item.status === "APROVADO" ? true : false} style={{ border: "none", marginRight: "5px" }}>
                                            {item.tipoAssinatura === "DIGITAL" ?
                                              <FiAtSign className="icones" size={25} /> :
                                              <FaFileSignature className="icones" size={25} />
                                            }
                                          </button>
                                        </Tooltip>
                                        <Tooltip placement="top" arrow title="Reimprimir Boleto">
                                          <button disabled={item.status === "CANCELADO" ? true : item.status === "APROVADO" ? true : item.tipoPagamento === "RECIBO" ? true : vencimentoF <= hoje ? true : false} style={{ border: "none", marginRight: "5px" }} onClick={() => dadosBoleto(item)}>
                                            <AiOutlineBarcode className="icones" size={25} />
                                          </button>
                                        </Tooltip>

                                        <Tooltip placement="top" arrow title="Enviar contrato por email">
                                          <button disabled={item.status === "CANCELADO" ? true : item.status === "APROVADO" ? true : (item.idContrato ? false : true)} style={{ border: "none", marginRight: "5px" }}>
                                            <MdEmail className="icones" onClick={teste} size={25} />
                                          </button>
                                        </Tooltip>
                                        {usuario?.dadoUsuario.cargo?.id === 3 ?
                                          <Tooltip placement="top" arrow title="Gerar relatório">
                                            <button disabled={item.idContrato ? false : true} style={{ border: "none", marginRight: "5px" }} onClick={() => preRelatorio(item)}>
                                              <MdDescription className="icones" size={25} />
                                            </button>
                                          </Tooltip> :
                                          <Tooltip placement="top" arrow title="Gerar relatório">
                                            <button disabled={item.status === "CANCELADO" ? true : item.status === "APROVADO" ? true : (item.idContrato ? false : true)} style={{ border: "none", marginRight: "5px" }} onClick={() => preRelatorioREP(item)}>
                                              <MdDescription className="icones" size={25} />
                                            </button>
                                          </Tooltip>

                                        }
                                        {item.status !== "CANCELADO" ?
                                          <Tooltip placement="top" arrow title="Cancelar contrato" onClick={() => dadosPreCancelamento(item)}>
                                            <button disabled={item.status === "APROVADO" ? true : false} style={{ border: "none", marginRight: "5px" }}>
                                              <MdCancel className="icones" size={25} />
                                            </button>
                                          </Tooltip> :
                                          <Tooltip placement="top" arrow title="Motivo cancelamento" onClick={() => dadosPreCancelamento(item)}>
                                            <button style={{ border: "none", marginRight: "5px" }}>
                                              <BsFillEyeFill className="icones" size={25} />
                                            </button>
                                          </Tooltip>}
                                        <Tooltip placement="top" arrow title="Anexar arquivos" onClick={() => dadosPreUpload(item)}>
                                          <button disabled={usuario?.dadoUsuario.cargo?.id === 3 ? false : (item.status === "CANCELADO" ? true : item.status === "APROVADO" ? true : false)} style={{ border: "none", marginRight: "15px" }}>
                                            <Badge badgeContent={item.qtdeAnexos || 0} color="primary">
                                              <MdOutlineAttachFile className="icones" style={(item.qtdeAnexos || 0) > 0 ? { color: "#1976D2" } : {}} size={25} />
                                            </Badge>

                                          </button>
                                        </Tooltip>
                                        {usuario?.id===2?
                                        <Tooltip placement="top" arrow title="Reenviar" onClick={() => reenviarD4Sign(item)}>
                                          <button style={{ border: "none", marginRight: "15px" }}>
                                              <MdSend className="icones" style={{ color: "#1976D2" }} size={25} />
                                          </button>
                                        </Tooltip>
                                        :<></>}

                                      </td>
                                    </tr>
                                  </>
                                )
                              })}

                              <tr style={{ height: "60" }}>
                                <td colSpan={10} style={{ height: "60" }}>
                                  <Pagination className="paginacao" count={count} page={page} onChange={handleChangePagination} size="large" style={{ marginTop: "10" }} />
                                </td>
                              </tr>

                            </tbody>
                          </Table>
                        </Tabelas1>
                      </div> :
                      <>
                        <div className="bemSelecionado">
                          <Row>
                            <Col min='220px' max='320px' className="tituloBem">Plano selecionado <FiEdit2 style={{ cursor: "pointer" }} onClick={() => setOpen(true)}></FiEdit2></Col>
                            <Col className="tituloBem direita"><div className="direita1"><FiX style={{ cursor: "pointer" }}></FiX></div></Col>
                          </Row>


                          <>

                            <Typography>

                              <Table hover bordered responsive className="fonteTI">

                                <tbody>
                                  <tr style={{ backgroundColor: "#f4f4f4e1" }}>
                                    <td className="ac n tamanhoAC">Grupo</td>
                                    <td className="ac n">Bem</td>
                                    <td className="ac n">Valor do bem</td>
                                    <td className="ac n">Taxa Adm.</td>
                                    <td className="ac n">Próx. Assembleia</td>
                                    <td className="ac n">Prazo</td>
                                    <td className="ac n">Adesão</td>
                                    <td className="ac n">Adesão</td>
                                    <td className="ac n">Parcela</td>
                                    <td className="ac n">Parcela c/ seguro</td>
                                    <td className="ac n">Média Lance</td>

                                  </tr>

                                  <tr >
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.grupo} </td>
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.codB + " - "} {dadosVenda.descricaoBem}</td>
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.valorCreditoF}</td>
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.taxaAdministracao + '%'}</td>
                                    <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.prazo}</td>
                                    <td className="ac tam tamanhoAC">{dadosVenda.adesao + "%"}</td>
                                    <td className="ac tam">{dadosVenda.valorParcelaComSeguro >= 0 ? (dadosVenda.valorParcelaComSeguro / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</td>
                                    <td className="ac"><CircularProgressWithLabel size={25} variant="determinate" value={dadosVenda.mediaLance >= 0 ? parseFloat(dadosVenda.mediaLance.toFixed(2)) : 0} /></td>
                                  </tr>

                                </tbody>
                              </Table>

                            </Typography>
                          </>

                        </div>


                      </>
                    }


                  </> : <></>}
                </> : <></>}</div>

            </> :
              <>
                {visualizar === false ? <>

                  <div className="fab">
                    <button className="main1" onClick={visualizarSimulacao}>
                      <MdVisibility className="teste" />
                    </button>
                  </div>

                  <Tabelas1 style={{ padding: '0' }}>
                    <Table hover responsive bordered style={{ padding: '0', backgroundColor: "#f4f4f4e1" }}>

                      <tbody>
                        <tr>
                          <td><FiArrowLeft onClick={voltar} className='voltar1' /></td>
                          <td className="ac n tamanhoAC">Grupo</td>
                          <td className="ac n">Bem</td>
                          <td className="ac n">Valor do bem</td>
                          <td className="ac n">Taxa</td>
                          <td className="ac n">Próxima Assembleia</td>
                          <td className="ac n">Valor Parcela</td>
                          <td className="ac n">Prazo</td>
                          <td className="ac n">Média Lance</td>
                        </tr>

                        <tr>
                          <td></td>
                          <td className="tamanhoAC" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.grupo} </td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.codB + " -"} {dadosVenda.descricaoBem}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.valorCreditoF}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.taxaAdministracao}%</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.primeiraAssF}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.valorParcelaFormatado}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{dadosVenda.prazo}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}><CircularProgressWithLabel size={30} variant="determinate" value={parseFloat(dadosVenda.mediaLance.toFixed(2))} /></td>
                        </tr>

                      </tbody>
                    </Table>
                  </Tabelas1>

                  <div className="tabela4">

                    <Box
                      sx={{ flexGrow: 1, bgcolor: 'white', display: 'flex' }}
                    >
                      <Tabs
                        orientation="vertical"
                        //variant="scrollable"
                        value={value}
                        onChange={handleChangel}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 'solid 1px #d3d3d3e1', borderColor: 'divider', marginRight: '30px' }}
                        indicatorColor="secondary"
                        textColor="secondary"
                      >
                        <Tab icon={<MdOutlineAssignmentTurnedIn size={30} style={{ marginTop: '10px' }} color={valuel === 0 ? 'rgb(19,52,131)' : ''} />}
                          style={{ //maxWidth: '10px', 
                            //background: 'white' , 
                            //marginTop: '5px', 
                            //borderTop: 'solid 5px rgb(0 0 0 / 20%)', 
                            //boxShadow: '1px 5px 12px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%)' }} 
                            //arial-label="Item One"
                          }}
                          {...a11yProps(0)} />
                        <Tab icon={<MdDateRange size={30} color={valuel === 1 ? 'rgb(19,52,131)' : ''} />} {...a11yProps(1)} />
                        <Tab icon={<MdInfo size={30} color={valuel === 2 ? 'rgb(19,52,131)' : ''} />}  {...a11yProps(2)} />
                        <Tab icon={<MdSwapHoriz size={30} color={valuel === 3 ? 'rgb(19,52,131)' : ''} />} {...a11yProps(3)} />
                        <Tab icon={<MdIso size={30} color={valuel === 4 ? 'rgb(19,52,131)' : ''} />} {...a11yProps(4)} />

                        {/*}  <Tab icon={<MdOutlineAssignmentTurnedIn size={30} color={valuel === 0 ? 'rgb(19,52,131)' : ''} />} style={{ maxWidth: '10px', background: 'white' , marginTop: '5px', borderTop: 'solid 5px rgb(0 0 0 / 20%)', boxShadow: '1px 5px 12px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%)' }} arial-label="Item One" {...a11yProps(0)} />
                  <Tab icon={<MdDateRange size={30} color={valuel === 1 ? 'rgb(19,52,131)' : ''} />} style={{ width:20, height:20, maxWidth: 20, background: 'white', boxShadow: '1px 5px 12px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%)', marginTop: '5px' }} {...a11yProps(1)} />
                  <Tab icon={<MdInfo size={30} color={valuel === 2 ? 'rgb(19,52,131)' : ''} />} style={{ maxWidth: 20, background: 'white', boxShadow: '1px 5px 12px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%)', marginTop: '5px' }} {...a11yProps(2)} />
                  <Tab icon={<MdSwapHoriz size={30} color={valuel === 3 ? 'rgb(19,52,131)' : ''} />} style={{ maxWidth: 20, background: 'white', boxShadow: '1px 5px 12px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%)', marginTop: '5px' }} {...a11yProps(3)} />
                  <Tab icon={<MdIso size={30} color={valuel === 4 ? 'rgb(19,52,131)' : ''} />} style={{ maxWidth: 20, background: 'white', boxShadow: '1px 5px 12px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%)', marginTop: '5px', marginBottom: '5px' }} {...a11yProps(4)} />
                */}

                      </Tabs>
                      <TabPanel value={valuel} index={0}>
                        <Typography id="modal-modal-title" variant="h6" component="h5">
                          <div style={{ color: "rgb(19,52,131)", width: '900px', marginTop: '10px' }}>
                            <b>Redução</b>
                          </div>
                          <Row className="tipoPessoa">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              className="tipoPessoaRadio"
                              value={dadosVenda.reducao}
                              onChange={handleChangeCheckReducao}
                            >
                              {reducaoModal.map(item => (
                                <FormControlLabel value={item} control={<Radio size="small" sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} label={item + '%'} />
                              ))}
                            </RadioGroup>
                          </Row>
                        </Typography>

                        <Typography variant="h6" component="h5">
                          <div style={{ marginTop: "10px", color: "rgb(19,52,131)" }}>
                            <b>Adesão</b>
                          </div>
                        </Typography>

                        {bemSelecionado.map(item => {
                          if (item.reducao === valorReducao && item.parcelaInicial === 1 && dadosVenda.valorCredito !== undefined) {
                            if (item.adesao === 0 && item.reducao === 0 && dadosVenda.sequenciaAgrupamento === 0) {
                              selecionaBemFinal(item)
                              setSelectedValue1(item.sequenciaAgrupamento.toString())
                              let dadosPlanos = planosAgrupamento.filter(item2 => item2.sequenciaAgrupamento === item.sequenciaAgrupamento)
                              setPlanoAgrupamento(dadosPlanos)
                            }

                            return (
                              <>
                                <Tabelas1>
                                  <Table hover responsive bordered className="fonteTI" style={{ marginTop: "10px" }} >
                                    <tbody>
                                      <tr style={{ backgroundColor: "#f4f4f4e1" }}>
                                        <td className="ac n"></td>
                                        <td className="ac n">Valor Adesão</td>
                                        <td className="ac n">Parcela</td>
                                        <td className="ac n">Parcela com Seguro</td>
                                        <td></td>

                                      </tr>
                                      <tr style={{ fontSize: "18px" }}>
                                        <td className="ac redm"><div style={{ zIndex: "9999" }}>
                                          <FormControlLabel label={item.adesao + "%"} control={<Radio size="small" name="escolhaBem" onChange={handleChangeOption1} checked={selectedValue1 === (item.sequenciaAgrupamento).toString()}
                                            value={(item.sequenciaAgrupamento)}
                                            sx={{ color: 'rgb(19,52,131)', '&.Mui-checked': { color: 'rgb(19,52,131)' } }} />} onClick={() => selecionaBemFinal(item)} />
                                        </div></td>
                                        <td className="ac tam">{item.adesao === 0 ? '-' : (item.adesao * dadosVenda.valorCredito / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td className="ac tam">{item.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td className="ac tam">{item.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td className="ac tam" onClick={() => mostrar(item.sequenciaAgrupamento)}>{mostrarModal === item.sequenciaAgrupamento && visible ? <FiArrowUp></FiArrowUp> : <FiArrowDown></FiArrowDown>}</td>

                                      </tr>
                                    </tbody>
                                  </Table>
                                </Tabelas1>
                                {mostrarModal === item.sequenciaAgrupamento && visible ?
                                  <>
                                    <Tabelas1>
                                      <Table hover responsive bordered className="fonteTI">
                                        <thead style={{ backgroundColor: "#f4f4f4e1" }}>
                                          <tr style={{ backgroundColor: "#f4f4f4e1" }}>
                                            <th className="ac tamanhoAC">Parcela</th>
                                            <th className="ac">Valor S/ Seguro</th>
                                            <th className="ac">Valor C/ Seguro</th>

                                          </tr>


                                          {bemSelecionado.map(item2 => {
                                            if (item2.sequenciaAgrupamento === item.sequenciaAgrupamento)
                                              return (
                                                <>

                                                  <tr>
                                                    <td className="ac tamanhoAC">{item2.parcelaInicial} a {item2.parcelaFinal}</td>
                                                    <td className="ac">{item2.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td className="ac">{item2.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>

                                                  </tr>


                                                </>
                                              )
                                          })}
                                        </thead>
                                      </Table></Tabelas1></> : <></>

                                }

                              </>
                            )
                          }

                        })}


                      </TabPanel>
                      <TabPanel value={valuel} index={1}>
                        <Typography id="modal-modal-title" variant="h6" component="h4">
                          <div style={{ color: "rgb(19,52,131)", width: '900px', marginBottom: '20px', marginTop: '10px' }}>
                            <b>Calendário de Assembléia</b>
                          </div>
                          <Row>
                            <Col>
                              <ul>
                                <li>
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{
                                      borderRadius: '50%', border: '2px solid #9e9e9e', width: '12px', height: '12px'
                                    }}>



                                    </div>
                                    <div style={{
                                      left: '10px',
                                      height: '2px',
                                      width: 'calc(100% - 8px)',
                                      top: '5px',
                                      border: '1px solid #9e9e9e'
                                    }}>

                                    </div>
                                  </div>
                                </li>
                                <li style={{ marginLeft: '15px' }}>
                                  <div style={{ fontSize: '13px' }}>
                                    {assMes === 11 ?
                                      ass1['10'].dataAss : ''
                                    }
                                    {assMes === 10 ?
                                      ass1['09'].dataAss : ''
                                    }
                                    {assMes === 12 ?
                                      ass1['11'].dataAss : ''
                                    }


                                  </div>
                                  <div style={{ fontSize: '13px', marginTop: '-4px' }}>
                                    <b><FiClock /> 17h30</b>
                                  </div>
                                </li>
                                <li style={{ marginLeft: '15px' }}>
                                  <div style={{ fontSize: '13px' }}>
                                    {assMes === 11 ?
                                      ass1['10'].dataLot : ''
                                    }
                                    {assMes === 10 ?
                                      ass1['09'].dataLot : ''
                                    }
                                    {assMes === 12 ?
                                      ass1['11'].dataLot : ''
                                    } - Loteria Federal
                                  </div>
                                  <div style={{ fontSize: '13px' }}>
                                    {assMes === 11 ?
                                      ass1['10'].dataVen : ''
                                    }
                                    {assMes === 10 ?
                                      ass1['09'].dataVen : ''
                                    }
                                    {assMes === 12 ?
                                      ass1['11'].dataVen : ''
                                    } - Vencimento
                                  </div>
                                  <div style={{ fontSize: '13px' }}>
                                    <FaMapMarkerAlt /> Franca/SP
                                  </div>

                                </li>
                              </ul>
                            </Col>

                            <Col>
                              <ul style={{ color: "#133483" }}>
                                <li>
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{
                                      borderRadius: '50%', border: '2px solid #133483', width: '12px', height: '12px', backgroundColor: '#133483'
                                    }}>



                                    </div>
                                    <div style={{
                                      height: '2px',
                                      width: 'calc(100% - 8px)',
                                      top: '5px',
                                      border: '1px solid #133483'
                                    }}>

                                    </div>
                                  </div>
                                </li>
                                <li style={{ marginLeft: '15px' }}>
                                  <div style={{ fontSize: '13px' }}>
                                    {assMes === 11 ?
                                      ass1['11'].dataAss : ''
                                    }
                                    {assMes === 10 ?
                                      ass1['10'].dataAss : ''
                                    }
                                    {assMes === 12 ?
                                      ass1['12'].dataAss : ''
                                    }


                                  </div>
                                  <div style={{ fontSize: '13px', marginTop: '-4px' }}>
                                    <b><FiClock /> 17h30</b>
                                  </div>
                                </li>
                                <li style={{ marginLeft: '15px' }}>
                                  <div style={{ fontSize: '13px' }}>
                                    {assMes === 11 ?
                                      ass1['11'].dataLot : ''
                                    }
                                    {assMes === 10 ?
                                      ass1['10'].dataLot : ''
                                    }
                                    {assMes === 12 ?
                                      ass1['12'].dataLot : ''
                                    } - Loteria Federal
                                  </div>
                                  <div style={{ fontSize: '13px' }}>
                                    {assMes === 11 ?
                                      ass1['11'].dataVen : ''
                                    }
                                    {assMes === 10 ?
                                      ass1['10'].dataVen : ''
                                    }
                                    {assMes === 12 ?
                                      ass1['12'].dataVen : ''
                                    } - Vencimento
                                  </div>
                                  <div style={{ fontSize: '13px' }}>
                                    <FaMapMarkerAlt /> Franca/SP
                                  </div>

                                </li>
                              </ul>
                            </Col>

                            <Col>
                              <ul>
                                <li>
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{
                                      borderRadius: '50%', border: '2px solid #9e9e9e', width: '12px', height: '12px'
                                    }}>



                                    </div>
                                    <div style={{
                                      left: '10px',
                                      height: '2px',
                                      width: 'calc(100% - 8px)',
                                      top: '5px',
                                      border: '1px solid #9e9e9e'
                                    }}>

                                    </div>
                                  </div>
                                </li>
                                <li style={{ marginLeft: '15px' }}>
                                  <div style={{ fontSize: '13px' }}>
                                    {assMes === 11 ?
                                      ass1['12'].dataAss : ''
                                    }
                                    {assMes === 10 ?
                                      ass1['11'].dataAss : ''
                                    }
                                    {assMes === 12 ?
                                      ass1['12'].dataAss : ''
                                    }


                                  </div>
                                  <div style={{ fontSize: '13px', marginTop: '-4px' }}>
                                    <b><FiClock /> 17h30</b>
                                  </div>
                                </li>
                                <li style={{ marginLeft: '15px' }}>
                                  <div style={{ fontSize: '13px' }}>
                                    {assMes === 11 ?
                                      ass1['12'].dataLot : ''
                                    }
                                    {assMes === 10 ?
                                      ass1['11'].dataLot : ''
                                    }
                                    {assMes === 12 ?
                                      ass1['12'].dataLot : ''
                                    } - Loteria Federal
                                  </div>
                                  <div style={{ fontSize: '13px' }}>
                                    {assMes === 11 ?
                                      ass1['12'].dataVen : ''
                                    }
                                    {assMes === 10 ?
                                      ass1['11'].dataVen : ''
                                    }
                                    {assMes === 12 ?
                                      ass1['12'].dataVen : ''
                                    } - Vencimento
                                  </div>
                                  <div style={{ fontSize: '13px' }}>
                                    <FaMapMarkerAlt /> Franca/SP
                                  </div>

                                </li>
                              </ul>
                            </Col>
                          </Row>

                        </Typography>
                      </TabPanel>
                      <TabPanel value={valuel} index={2}>
                        <Typography id="modal-modal-title" variant="h6" component="h4">
                          <div style={{ color: "rgb(19,52,131)", width: '900px', marginBottom: '20px', marginTop: '10px' }}>
                            <b>Características</b>
                          </div>
                          <Row style={{ width: "900px", color: "rgb(19,52,131)", marginTop: 20 }}>
                            <h6 style={{ margin: 0 }}>
                              Grupo
                            </h6>
                            <p className="text-carac" style={{ fontSize: '13px', color: '#666666' }}>
                              Grupo constituído de créditos de valores diferenciados
                            </p>
                          </Row>
                          <Col className="title-blue no-margin">
                            <Row>
                              <Col style={{ marginBottom: '10px' }}>
                                <p className="title-carac">Grupo</p>
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                  <FaListOl />
                                  <p className="text-carac">{dadosVenda.grupo}</p>
                                </div>
                              </Col>
                              <Col>
                                <p className="title-carac">Espécie</p>
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                  {dadosVenda.grupo === "55" ? <MdLocalShipping /> :
                                    dadosVenda.grupo === "56" ? <FaHome /> : <MdDirectionsCar />}
                                  <p className="text-carac">{dadosVenda.grupo === "55" ? 'CAMINHÕES' :
                                    dadosVenda.grupo === "56" ? 'IMÓVEIS' : 'AUTOMÓVEIS'}
                                  </p>
                                </div>
                              </Col>
                              <Col>
                                <p className="title-carac">Última atualização da Tabela</p>
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                  <FiClock />
                                  <p className="text-carac">06/10/2021</p>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col style={{ marginBottom: '10px' }}>
                                <p className="title-carac">Prazo de duração</p>
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                  <MdOutlineTimer />
                                  <p className="text-carac">{dadosVenda.planoBasico} Meses</p>
                                </div>
                              </Col>
                              <Col>
                                <p className="title-carac">Máximo de consorciados ativos</p>
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                  <MdOutlineShowChart />
                                  <p className="text-carac">{dadosVenda.numMax}
                                  </p>
                                </div>
                              </Col>
                              <Col>
                                <p className="title-carac">Índice de correção</p>
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                  <BsListCheck />
                                  <p className="text-carac">{dadosVenda.indiceCorrecao}</p>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              {renderCaracteristica('Sede do grupo', 'Franca/SP', <FaMapMarkerAlt />)}
                              {renderCaracteristica('Local da assembleia', 'Franca/SP', <FaMapMarkerAlt />)}
                              <Col />
                            </Row>
                          </Col>

                          <Row style={{ width: "900px", color: "rgb(19,52,131)", marginTop: 20 }}>
                            <h6 style={{ margin: 0 }}>
                              Dados do plano
                            </h6>
                          </Row>
                          <Row style={{ marginTop: "20px" }}>
                            <Col>
                              <div style={{ color: '#133483', fontSize: '16px' }}>
                                Taxa administrativa mensal
                              </div>
                              <div style={{ fontSize: '13px' }}>
                                <ul>
                                  {planoAgrupamento?.map(function (item) {
                                    return (
                                      <li>
                                        {item.parcelaInicial.toLocaleString('en-US', { minimumIntegerDigits: 3 })} - {item.parcelaFinal.toLocaleString('en-US', { minimumIntegerDigits: 3 })} = {item.percentualTaxaAdministracao.toFixed(4).replace('.', ',')}%
                                      </li>
                                    )
                                  })}

                                </ul>

                              </div>
                            </Col>
                            <Col>
                              <div style={{ color: '#133483', fontSize: '16px' }}>
                                Taxa administrativa total
                              </div>
                              <div style={{ fontSize: '13px' }}>
                                <ul>
                                  <li>
                                    {dadosVenda.taxaAdministracao}%
                                  </li>
                                </ul>

                              </div>
                            </Col>
                            <Col>
                              <div style={{ color: '#133483', fontSize: '16px' }}>
                                Adesão/Administrativa antecipada
                              </div>
                              <div style={{ fontSize: '13px' }}>
                                <ul>
                                  <li>
                                    {dadosVenda.adesao}%
                                  </li>
                                </ul>

                              </div>
                            </Col>

                          </Row>

                          <Row style={{ marginTop: "5px" }}>
                            <Col>
                              <div style={{ color: '#133483', fontSize: '16px' }}>
                                Fundo comum mensal
                              </div>
                              <div style={{ fontSize: '13px' }}>
                                <ul>
                                  {planoAgrupamento?.map(function (item) {
                                    return (
                                      <li>
                                        {item.parcelaInicial.toLocaleString('en-US', { minimumIntegerDigits: 3 })} - {item.parcelaFinal.toLocaleString('en-US', { minimumIntegerDigits: 3 })} = {item.percentualNormal.toFixed(4).replace('.', ',')}%
                                      </li>
                                    )
                                  })}

                                </ul>

                              </div>
                            </Col>
                            <Col>
                              <div style={{ color: '#133483', fontSize: '16px' }}>
                                Fundo comum total
                              </div>
                              <div style={{ fontSize: '13px' }}>
                                <ul>
                                  <li>
                                    100%
                                  </li>
                                </ul>

                              </div>
                            </Col>
                            <Col>
                              <div style={{ color: '#133483', fontSize: '16px' }}>
                                Seguro de Vida
                              </div>
                              <div style={{ fontSize: '13px' }}>
                                <ul>
                                  <li>
                                    0,0516%
                                  </li>
                                </ul>

                              </div>
                            </Col>

                          </Row>
                        </Typography>
                      </TabPanel>
                      <TabPanel value={valuel} index={3}>
                        <Row>
                          <Col>
                            <div style={{ color: "rgb(19,52,131)", width: '900px', fontSize: '20px', marginTop: '10px' }}>
                              <b>Comparativo Groscon x Financiamento</b>

                            </div>
                          </Col>
                          <Col>
                            <div style={{ color: "rgb(19,52,131)", marginTop: '10px' }}></div>
                          </Col>
                        </Row>

                        <div style={{ color: "rgb(19,52,131)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <b>Consórcio Groscon </b>
                          <div style={{ fontSize: '20px', marginLeft: '8px' }}>
                            {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                              dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                          </div>

                        </div>
                        <Row>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do bem</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF} </div>
                            </div>

                          </Col>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo} </div>
                            </div>
                          </Col>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa de administração mensal</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.nomePai?.replace('.', ',')}% </div>
                            </div>
                          </Col>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Valor da parcela mensal</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorParcelaFormatado} </div>
                            </div>
                          </Col>

                        </Row>

                        <div style={{ color: "rgb(19,52,131)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <b>Financiamento</b>

                          <div style={{ fontSize: '20px', marginLeft: '8px' }}>
                            {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                              dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                          </div>
                          <div style={{ maxWidth: `${dadosVenda.naturalCidade}`, overflow: 'hidden', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontSize: '20px' }}>
                              {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                                dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                            </div>
                          </div>
                        </div>
                        <Row>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do bem</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF} </div>
                            </div>

                          </Col>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo} </div>
                            </div>
                          </Col>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa mensal</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.descTipo === 'IMÓVEL' ? '0,80%' : '1,50%'}</div>
                            </div>
                          </Col>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Valor da parcela mensal</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.cidade} </div>
                            </div>
                          </Col>

                        </Row>

                        <div style={{ color: "rgb(19,52,131)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                          <b>Economia com o Consórcio Groscon</b>
                        </div>
                        <Row>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Mensal</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.uf} </div>
                            </div>

                          </Col>
                          <Col>
                            <div style={{ backgroundColor: '#F7F7F7', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                              <div style={{ fontSize: '12px', color: '#63758E' }}>Total</div>
                              <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.apto} </div>
                            </div>
                          </Col>

                        </Row>
                        <Row>
                          <Col>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

                              <div style={{ fontSize: '120px', color: '#63758E' }}><CircularProgressWithLabel1 style={{ borderRadius: '50%' }} size={120} variant="determinate" value={dadosVenda.codBanco || 0} /> </div>
                              <div style={{ fontSize: '12px', color: '#63758E', marginTop: '-33px' }}>Economia(%)</div>
                            </div>
                          </Col>

                        </Row>
                      </TabPanel>

                    </Box>


                  </div>

                  <Typography>
                    <Tabelas1>
                      <div className="testea">
                      </div>
                    </Tabelas1>
                  </Typography>
                </> : <>
                  <div className="tabela">
                    <Form className="form-border">
                      <Col>
                        <div style={{ color: "rgb(19,52,131)", marginTop: '10px' }}><FiArrowLeft onClick={visualizarSimulacao} className='voltar1' /></div>
                      </Col>


                      <Row >
                        <Col xs={6}>
                          <div className="inputNovo">
                            <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                              <TextField
                                className='inputNovo'
                                id="standard-required"
                                label="Nome Cliente"
                                variant="standard"
                                name='emailCom1'
                                value={dadosVenda.emailCom1}
                                onChange={alteraDadosSimulacao}
                              />
                            </FormControl>
                          </div>
                        </Col>
                        <Col xs={1}>
                          <div className="inputNovo">
                            <FormControl className="inputNovo" variant="standard" style={{ alignItems: 'center', marginTop: 7 }} >
                              <TextField
                                className='inputNovo'
                                id="standard-required"
                                label="DDD"
                                variant="standard"
                                name='emailCom2'
                                value={dadosVenda.emailCom2}
                                onChange={alteraDadosSimulacao}
                                InputLabelProps={{
                                  style: { alignItems: 'center' },
                                }}

                                InputProps={{
                                  sx: { "& input": { textAlign: "center" }, "& label": "center" },
                                  style: { textAlign: "end", alignItems: 'center', justifyContent: 'center', alignContent: 'center' }
                                }}

                              />
                            </FormControl>
                          </div>
                        </Col>
                        <Col xs={5}>
                          <div className="inputNovo">
                            <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                              <TextField
                                className='inputNovo'
                                id="standard-required"
                                label="Telefone"
                                variant="standard"
                                name='emailCom3'
                                value={dadosVenda.emailCom3}
                                onChange={alteraDadosSimulacao}
                              />
                            </FormControl>
                          </div>
                        </Col>


                      </Row>
                      <Row style={{ marginBottom: '20px' }}>
                        <Col xs={12}>
                          <div className="inputNovo">
                            <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                              <TextField
                                className='inputNovo'
                                id="standard-required"
                                label="E-mail"
                                variant="standard"
                                name='emailCom4'
                                value={dadosVenda.emailCom4}
                                onChange={alteraDadosSimulacao}
                              />
                            </FormControl>
                          </div>
                        </Col>


                      </Row>



                    </Form>
                  </div>
                  <div className="tabela">
                    <Row>
                      <Col>
                        <div style={{ color: "rgb(19,52,131)", fontSize: '20px', marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <b>Simulação de consórcio</b>

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Grupo</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.grupo} </div>
                        </div>

                      </Col>
                      <Col>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa administração</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.taxaAdministracao}%</div>
                        </div>
                      </Col>
                      <Col>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Adesão</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.adesao}%</div>
                        </div>
                      </Col>
                      <Col>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do crédito</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF}</div>
                        </div>
                      </Col>

                    </Row>
                    <Row>
                      <Col>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Redução</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.reducao}% </div>
                        </div>

                      </Col>
                      <Col>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Média de Lance</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.mediaLance}% </div>
                        </div>
                      </Col>
                      <Col>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo}</div>
                        </div>
                      </Col>
                      <Col>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Próxima assembleia</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.primeiraAssF} </div>
                        </div>
                      </Col>

                    </Row>
                    <Row>
                      <Col >
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Parcela</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorParcelaFormatado} </div>
                        </div>

                      </Col>
                      <Col style={{ marginBottom: '13px' }}>
                        <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                          <div style={{ fontSize: '12px', color: '#63758E' }}>Parcela com seguro</div>
                          <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorParcelaComSeguroFormatado} </div>
                        </div>
                      </Col>

                    </Row>

                  </div>
                  {excluirLance ? <>
                    <div className="tabela">
                      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', color: "rgb(19,52,131)" }}><AiFillDelete style={{ cursor: 'pointer' }} onClick={excluirLance1} size={20} /></div>
                      <Row>

                        <div style={{ color: "rgb(19,52,131)", width: '100%', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <b>Simulação Lance</b>

                        </div>

                      </Row>
                      <Row>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Percentual Lance</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.b3}% </div>
                          </div>

                        </Col>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor total do lance</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{((dadosVenda.t_p2 || 0) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </div>
                          </div>
                        </Col>

                      </Row>
                      <Row>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor lance embutido</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{((dadosVenda.t_p3 || 0) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </div>
                          </div>

                        </Col>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do lance com recursos próprios</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.b4} </div>
                          </div>
                        </Col>

                      </Row>
                      <Row >
                        <Col style={{ marginBottom: '13px' }}>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Crédito liquido com lance embutido</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.b5} </div>
                          </div>

                        </Col>

                      </Row>


                    </div>
                  </> : <></>}
                  {excluirComparativo ? <>
                    <div className="tabela">
                      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', color: "rgb(19,52,131)" }}><AiFillDelete style={{ cursor: 'pointer' }} onClick={excluirComparativo1} size={20} /></div>
                      <Row>

                        <div style={{ color: "rgb(19,52,131)", width: '100%', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <b>Comparativo Groscon x Financiamento</b>

                        </div>

                      </Row>

                      <div style={{ color: "rgb(19,52,131)", marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <b>Consórcio Groscon </b>
                        <div style={{ fontSize: '20px', marginLeft: '8px' }}>
                          {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                            dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                        </div>

                      </div>
                      <Row>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do bem1</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF} </div>
                          </div>

                        </Col>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo} </div>
                          </div>
                        </Col>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa de administração mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.nomePai?.replace('.', ',')}% </div>
                          </div>
                        </Col>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor da parcela mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorParcelaFormatado} </div>
                          </div>
                        </Col>

                      </Row>

                      <div style={{ color: "rgb(19,52,131)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <b>Financiamento</b>

                        <div style={{ fontSize: '20px', marginLeft: '8px' }}>
                          {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                            dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                        </div>
                        <div style={{ maxWidth: `${dadosVenda.naturalCidade}`, overflow: 'hidden', display: 'flex', flexDirection: 'row' }}>
                          <div style={{ fontSize: '20px' }}>
                            {dadosVenda.grupo === "55" ? <MdLocalShipping size={20} /> :
                              dadosVenda.grupo === "56" ? <FaHome size={20} /> : <MdDirectionsCar size={20} />}
                          </div>
                        </div>
                      </div>
                      <Row>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor do bem</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.valorCreditoF} </div>
                          </div>

                        </Col>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Prazo</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.prazo} </div>
                          </div>
                        </Col>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Taxa mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.descTipo === 'IMÓVEL' ? '0,80%' : '1,50%'}</div>
                          </div>
                        </Col>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Valor da parcela mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.cidade} </div>
                          </div>
                        </Col>

                      </Row>

                      <div style={{ color: "rgb(19,52,131)", marginTop: '30px', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                        <b>Economia com o Consórcio Groscon</b>
                      </div>
                      <Row>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Mensal</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.uf} </div>
                          </div>

                        </Col>
                        <Col>
                          <div style={{ backgroundColor: '#e4e4e4fb', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                            <div style={{ fontSize: '12px', color: '#63758E' }}>Total</div>
                            <div style={{ fontSize: '22px', color: '#63758E' }}>{dadosVenda.apto} </div>
                          </div>
                        </Col>

                      </Row>
                      <Row>
                        <Col>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

                            <div style={{ fontSize: '120px', color: '#63758E' }}><CircularProgressWithLabel1 style={{ borderRadius: '50%' }} size={120} variant="determinate" value={dadosVenda.codBanco || 0} /> </div>
                            <div style={{ fontSize: '12px', color: '#63758E', marginTop: '-33px' }}>Economia(%)</div>
                          </div>
                        </Col>

                      </Row>
                    </div>
                  </> : <></>}

                  <div className="fab">
                    <button className="main">
                    </button>
                    <ul>
                      <li onClick={() => print("imprimir")}>
                        <label>Imprimir </label>
                        <button id="opcao1" >
                          <BsPrinterFill size={20} />
                        </button>
                      </li>
                      <li onClick={() => print("download")}>
                        <label>Download</label>
                        <button id="opcao2">
                          <FaFileDownload />
                        </button>
                      </li>
                      {/*  <li>
      <label>Enviar email</label>
      <button id="opcao3">
      <MdEmail />
      </button>
    </li>*/}
                    </ul>
                  </div>




                </>}
              </>}


          </Typography>

        </Tabelas>

        <div className="fab">
                    <button className="main13" onClick={consultaContratoImpressao}>
                      <BsPrinterFill className="teste56" />
                    </button>
                  </div>



      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={styleBox}>




          <Row>

            <Typography>
              <Tabelas1>
                <div className="titulo title1"><Typography style={{ border: "none", margin: "0" }} gutterBottom><h5 onClick={mostrarItemX}>Motivo Cancelamento</h5></Typography></div>

                <Typography id="modal-modal-title" variant="h6" component="h4">
                  <TextField
                    id="outlined-multiline-static"
                    label=""
                    multiline
                    disabled={itemCancelamento?.status === "CANCELADO"}
                    rows={4}
                    fullWidth
                    onChange={handleChangeBemInput}
                    value={itemCancelamento?.motivoCancelamento}

                  />

                  <Row className="tipoPessoa">

                  </Row>
                </Typography>

                <Typography>
                  <Tabelas1>

                    <div className="testea">
                      <Button onClick={handleClose} style={{ backgroundColor: "rgb(19,52,131)", marginRight: "20px" }}><Typography variant="button" gutterBottom>FECHAR</Typography></Button>
                      <Button disabled={((itemCancelamento?.status === "CANCELADO") || (itemCancelamento?.motivoCancelamento || "").length < 3) ? true : false} onClick={salvarCancelamento} style={{ backgroundColor: "rgb(19,52,131)" }}><Typography variant="button" gutterBottom></Typography>CONFIRMAR</Button>
                    </div>

                  </Tabelas1>




                </Typography>
              </Tabelas1>
            </Typography>
          </Row>
        </Box>

      </Modal>


      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={styleBox}>




          <Row>

            <Typography>
              <Tabelas1>
                <div className="titulo title1"><Typography style={{ border: "none", margin: "0" }} gutterBottom><h5 onClick={mostrarItemX}>Enviar Arquivos Groscon</h5></Typography></div>

                <Typography id="modal-modal-title">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                        <p><div style={{ border: "1px solid #133483", height: "120px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                          <BsFillCloudArrowUpFill size={50} color="#133483" />
                          <p style={{ color: "E5E5E5" }}>

                            <Typography>
                              Solte os arquivos aqui ou{window.screen.width < 600 ? <br /> : ""}SELECIONE DO COMPUTADOR
                            </Typography>


                          </p>
                        </div></p> :
                        <p>
                          <div style={{ border: "1px solid #E5E5E5", height: "120px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <BsFillCloudArrowUpFill size={50} color="#E5E5E5" />
                            <p style={{ color: "E5E5E5" }}><Typography>
                              Solte os arquivos aqui ou {window.screen.width < 600 ? <br /> : ""}SELECIONE DO COMPUTADOR
                            </Typography></p>
                          </div>



                        </p>
                    }
                  </div>
                  <div style={{ display: "flex", flexWrap: 'wrap' }}>
                    {listaArquivos.map(function (item) {
                      return (
                        <Card sx={{ width: 220, margin: '10px' }}>
                          <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                              {item.nomeOriginal}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                              <div style={{ width: '40px' }}><Link href={item.downloadLink}><FiDownload size={23} /></Link></div><div><MdDelete color="#616161" style={{ cursor: "pointer" }} onClick={() => deletaArquivo(item)} size={23} /></div>
                            </div>
                          </CardActions>
                        </Card>
                      )
                    })}
                  </div>



                  <Row className="tipoPessoa">

                  </Row>
                </Typography>

                <Typography>
                  <Tabelas1>

                    <div className="testea">
                      <Button onClick={() => fecharModal1()} style={{ backgroundColor: "rgb(19,52,131)", marginRight: "20px" }}><Typography variant="button" gutterBottom>FECHAR</Typography></Button>
                    </div>

                  </Tabelas1>




                </Typography>
              </Tabelas1>
            </Typography>
          </Row>
        </Box>

      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={styleBox}>




          <Row>

            <Typography>
              <Tabelas1>
                <div className="titulo title1"><Typography style={{ border: "none", margin: "0" }} gutterBottom><h5 onClick={mostrarItemX}>Dados Boleto</h5></Typography></div>

                <Typography id="modal-modal-title" variant="h6" component="h4">
                  <Card sx={{ width: '98%', margin: '10px' }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <p>
                          Nome Cliente: <b>{dadoBoleto?.nome}</b> <br />
                          Data Vencimento: <b>{(dadoBoleto) ? formatData(dadoBoleto?.dataVencimento) : <></>}</b><br />
                          Valor: <b>{dadoBoleto?.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b>

                        </p>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                        {(dadoBoleto) ?
                          <div style={{ width: '40px' }}><Link href={(dadoBoleto?.link) ? dadoBoleto?.link : `www`} target='blank'><FiDownload size={40} /></Link></div>
                          : <></>
                        }
                      </div>
                    </CardActions>
                  </Card>


                  <Row className="tipoPessoa">

                  </Row>
                </Typography>

                <Typography>
                  <Tabelas1>

                    <div className="testea">
                      <Button onClick={() => fecharModal2()} style={{ backgroundColor: "rgb(19,52,131)", marginRight: "20px" }}><Typography variant="button" gutterBottom>FECHAR</Typography></Button>
                    </div>

                  </Tabelas1>




                </Typography>
              </Tabelas1>
            </Typography>
          </Row>
        </Box>

      </Modal>
    </>
  )
}

export default ConsultaContrato